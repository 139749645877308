import React from 'react'
import axios from 'axios'
import { toast } from 'sonner'
import { useLoaderData } from 'react-router'
import SoraLink from '@components/link'
import { StudentAdd } from './student-add'
import { EmptyState } from '@design-system'
import useClipboard from '@hooks/useClipboard'
import { StudentDetach } from './student-detach'
import EmptyStateIllustration from './empty-state.svg'
import serializeFormData from '@utils/serializeFormData'
import { Button, Icon, unstable_Tooltip as Tooltip, unstable_Table as Table, Typography, Avatar, Card, Pill } from '@design-system'

type LoaderData = Awaited<ReturnType<typeof loader>>

async function loader({ request, params }) {
  const { experienceId } = params
  const searchParams = new URL(request.url).searchParams
  const action = searchParams.get('_action') || 'default'

  const result = await axios.get(`/backoffice/experiences/${experienceId}/registrations`, { params: { action } })
  return result?.data
}

async function action({ params, request }) {
  const { experienceId } = params
  const formData = await request.formData()
  const serializedFormData = serializeFormData(formData)
  const action = serializedFormData._action

  if (action === 'cancel') return null

  try {
    const { data } = await axios.post(`/backoffice/experiences/${experienceId}/registrations`, { ...serializedFormData, action })
    return data
  } catch (error) {
    return {
      toast: { message: 'There was an unexpected error. If the problem persists, contact our tech support team.', appearance: 'error' }
    }
  }
}

const Element = () => {
  const [, copy] = useClipboard()
  const { students, canManageRegistrations, isFull, maxStudents, isExtracurricular, isExternalStudentsFFEnabled } = useLoaderData() as LoaderData

  const copyTableContentToClipboard = () => {
    try {
      const output = ['Student Name\tStudent Email\tGuardian Name\tGuardian Email']
      const activeStudents = students.filter(s => s.status !== 'Withdrawn')
      for (const student of activeStudents) {
        output.push([
          student.name,
          student.email,
          student.guardian_name,
          student.guardian_email || ''
        ].join('\t'))
      }
      copy(output.join("\n"))
      toast.success('Table Content Copied!')
    } catch (e) {
      toast.error('Something went wrong')
    }
  }

  const copyToClipboard = (value) => {
    try {
      copy(value)
      toast.success('Content Copied!')
    } catch (e) {
      toast.error('Something went wrong')
    }
  }

  const copyAllGuardiansEmailToClipboard = () => {
    try {
      copy(students.filter(s => s.status !== 'Withdrawn').map(s => s.guardians?.map(g => g.email)?.join("\n"))?.join("\n"))
      toast.success('Content Copied!')
    } catch (e) {
      toast.error('Something went wrong')
    }
  }

  const copyAllStudentsEmailToClipboard = () => {
    try {
      copy(students.filter(s => s.status !== 'Withdrawn').map(s => s.email)?.join("\n"))
      toast.success('Content Copied!')
    } catch (e) {
      toast.error('Something went wrong')
    }
  }

  return (
    <Card>
      <Card.Content padding='xs' className='overflow-visible'>
        <Table>
          <Table.Header className="sticky top-0 z-50 bg-white dark:bg-gray-95">
            <Table.Row>
              <Table.Head colSpan={canManageRegistrations ? 3 : 2}>
                <div className='flex flex-row justify-between items-center pl-4 py-2 gap-2'>
                  <Typography variant="subheadline" className='grow' weight="bold">Registrations <span className='font-normal'>({students?.length || 0} / {maxStudents})</span></Typography>
                  <Button variant='outlined' size='sm' color='soft' onClick={copyTableContentToClipboard} data-testid="copy-table-content-btn">
                    <Icon name='file-download' size='sm' title='Copy registrations' />
                  </Button>
                  {(canManageRegistrations && students?.length > 0) && <StudentAdd isDark={true} isExtracurricular={isExtracurricular && isExternalStudentsFFEnabled} isFull={isFull} />}
                </div>
              </Table.Head>
            </Table.Row>
            <Table.Row className={`${students?.length ? '' : 'hidden'}`}>
              <Table.Head className="first:pl-4 text-left">
                <div className='flex flex-row items-center gap-2 group'>
                  <span>Name</span>
                  <Tooltip content="Copy E-mails">
                    <Button variant='ghost' size='xs' className='group-hover:opacity-100 opacity-0 transition-opacity' onClick={() => copyAllStudentsEmailToClipboard()} data-testid="copy-student-emails-btn">
                      <Icon name='email' size='xs' />
                    </Button>
                  </Tooltip>
                </div>
              </Table.Head>
              <Table.Head className="text-left">
                <div className='flex flex-row items-center gap-2 group'>
                  <span>Guardians</span>
                  <Tooltip content="Copy E-mails">
                    <Button variant='ghost' size='xs' className='group-hover:opacity-100 opacity-0 transition-opacity' onClick={() => copyAllGuardiansEmailToClipboard()} data-testid="copy-guardian-emails-btn">
                      <Icon name='email' size='xs' />
                    </Button>
                  </Tooltip>
                </div>
              </Table.Head>
              {canManageRegistrations && <Table.Head className="w-full"></Table.Head>}
            </Table.Row>
            {students?.length === 0 && (
              <Table.Row>
                <Table.Head colSpan={canManageRegistrations ? 3 : 2}>
                  <EmptyState>
                    <EmptyState.Illustration>
                      <img src={EmptyStateIllustration} alt="Hand drawn illustration of a puppet pointing to a rotatory globe" />
                    </EmptyState.Illustration>
                    <EmptyState.Title>No registrations yet</EmptyState.Title>
                    <EmptyState.Description>This experience doesn't have any registrations.</EmptyState.Description>
                    <EmptyState.Actions>
                      {canManageRegistrations && <StudentAdd isDark={true} isExtracurricular={isExtracurricular && isExternalStudentsFFEnabled} isFull={isFull} />}
                    </EmptyState.Actions>
                  </EmptyState>
                </Table.Head>
              </Table.Row>
            )}
          </Table.Header>
          <Table.Body>
            {students?.map((student) => {
              const active = !student.status
              return (
                <Table.Row key={student.id} data-state={student.id} className='hover:bg-alpha/5 dark:hover:bg-alpha/10'>
                  <Table.Cell className="first:pl-4">
                    <div className='flex items-center gap-3 group'>
                      <div className='flex flex-row gap-2'>
                        <Avatar size='xl' className='rounded-full'>
                          <Avatar.Image src={student.picture} alt={student.name} />
                          <Avatar.Fallback>{student.name.substring(0, 10)}</Avatar.Fallback>
                        </Avatar>
                        <div className='flex flex-col text-nowrap'>
                          <SoraLink to={student.external ? `/workbench/external-students/${student.id}/overview` : `/employee/students/${student.id}`} className='flex flex-row font-bold items-center gap-2'>
                            {student.name}
                          </SoraLink>
                          <span>{student.grade ? `${student.grade}th grade` : 'No grade'}</span>
                        </div>
                      </div>
                      {!active && <Pill color='danger-10'>{student.status}</Pill>}
                      {student.external && isExternalStudentsFFEnabled && <Pill color='blue-5'>External Student</Pill>}
                      {student.unavailability &&
                        <Tooltip content={student.unavailability?.join('\n')}>
                          <Pill color='warning-20'>Unavailable</Pill>
                        </Tooltip>
                      }
                      {student.conflicts &&
                        <Tooltip content={student.conflicts?.join('\n')}>
                          <Pill color='danger-10'>Conflict</Pill>
                        </Tooltip>
                      }
                      <Tooltip content="Copy E-mail">
                        <Button variant='ghost' size='xs' className='group-hover:opacity-100 opacity-0 transition-opacity' onClick={() => copyToClipboard(student.email)} data-testid="copy-student-emails-btn">
                          <Icon name='email' size='xs' />
                        </Button>
                      </Tooltip>
                    </div>
                  </Table.Cell>
                  <Table.Cell>
                    <div className='flex flex-row gap-2 group items-center'>
                      <div className='flex flex-col gap-2 text-nowrap'>
                        {student.guardians?.map((guardian) => (<span key={guardian.id}>{guardian.name}</span>))}
                      </div>
                      <Tooltip content="Copy E-mails">
                        <Button variant='ghost' size='xs' className='group-hover:opacity-100 opacity-0 transition-opacity' onClick={() => copyToClipboard(student.guardians?.map(g => g.email)?.join('\n'))} data-testid="copy-student-emails-btn">
                          <Icon name='email' size='xs' />
                        </Button>
                      </Tooltip>
                    </div>
                  </Table.Cell>
                  {canManageRegistrations && (
                    <Table.Cell className="text-right">
                      <StudentDetach studentId={student.id} actionName={student.external && isExternalStudentsFFEnabled ? 'detach_external_student' : 'detach_student'} />
                    </Table.Cell>
                  )}
                </Table.Row>
              )
            })}
          </Table.Body>
        </Table>
      </Card.Content>
    </Card>
  )
}

export const ExpeditionRegistrationsRoute = {
  loader,
  action,
  Element,
}
