import React from 'react'
import axios from 'axios'
import { Outlet, useLoaderData, useSearchParams } from 'react-router'
import utcToZonedTime from 'date-fns-tz/utcToZonedTime'
import { EmptyState, Typography, Accordion, Button } from '@design-system'
import SoraLink from '@components/link'
import Illustration from './illustration.svg'
import { RenderedSlate } from '@components/forms/slate-textarea'
import { format } from 'date-fns'
import { CardStudentTimeline, TimelineEvent } from '@blocks/card-student-timeline'

interface EmployeeStudentNotesProps {
  notes: {
    id: number
    title: string
    dueAt: string
    type: string
    employeeFullName: string
    body: string[]
  }[]
  events: TimelineEvent[]
}

export async function loader({ params }) {
  const { data } = await axios.get(`/backoffice/employee/students/${params.studentId}/notes`)
  return data
}

function Element() {
  const data = useLoaderData() as EmployeeStudentNotesProps
  const [searchParams] = useSearchParams()
  const noteId = searchParams.get('note_id')
  return (
    <div className="flex flex-col md:flex-row gap-4">
      <div className="grow space-y-4">
        <header className="flex gap-2 items-center">
          <Typography variant="heading-6" weight="bold" className="grow">Notes</Typography>
          <Button asChild size="sm">
            <SoraLink to="form">
              Add note
            </SoraLink>
          </Button>
        </header>
        {data?.notes?.length === 0 && (
          <EmptyState>
            <EmptyState.Illustration>
              <img src={Illustration} alt="No notes found" />
            </EmptyState.Illustration>
            <EmptyState.Title>No Notes</EmptyState.Title>
            <EmptyState.Description>This student doesn't have any notes</EmptyState.Description>
          </EmptyState>
        )}
        <Accordion type="single" defaultValue={noteId} collapsible>
          {data?.notes.map((row) => (
            <Accordion.Item key={row.id} value={String(row.id)}>
              <Accordion.Header>
                <Accordion.Trigger className="justify-between items-center">
                  <div className="flex flex-col grow">
                    <div className="flex items-center justify-between">
                      <Typography variant="subheadline" weight="bold">{row.title}</Typography>
                      <Typography variant="footnote" className="whitespace-nowrap">
                        {format(new Date(utcToZonedTime(row.dueAt, 'UTC')), 'MMM dd, yyyy')}
                      </Typography>
                    </div>
                    <div className="flex items-center justify-between">
                      <Typography variant="body">{row.type}</Typography>
                      <Typography variant="footnote">By {row.employeeFullName}</Typography>
                    </div>
                  </div>
                  <Accordion.Icon />
                </Accordion.Trigger>
              </Accordion.Header>
              <Accordion.Content>
                <div className="flex justify-end my-4">
                  <Button size="xs" variant="outlined" asChild>
                    <SoraLink to={`form/${row.id}`}>
                      Edit
                    </SoraLink>
                  </Button>
                </div>
                <RenderedSlate value={row.body} />
              </Accordion.Content>
            </Accordion.Item>
          ))}
        </Accordion>
      </div>
      <CardStudentTimeline events={data.events} />
      <Outlet />
    </div>
  )
}

export const EmployeeStudentNotesRoute = {
  Element,
  loader,
}


