import React, { useRef } from 'react'
import axios from 'axios'
import { Await, Form, LoaderFunctionArgs, useLoaderData, useNavigation, useSearchParams, useSubmit, Outlet } from 'react-router'
import { Icon, Typography, unstable_Select as Select, DropdownMenu, Button, unstable_Tooltip as Tooltip, Pagination, FetcherForm, useFetcher } from '@design-system'
import format from 'date-fns/format'
import { ColumnDef } from '@tanstack/react-table'
import serializeFormData from '@utils/serializeFormData'
import { useLocalStorage } from '@hooks/useLocalStorage'
import { TextField } from '@designsystem'
import { DataTable } from './data-table'
import { HeaderStudent } from './header-student'
import { CellStudentName } from './cell-student-name'
import { CellId } from './cell-id'
import { CellAttendance } from './cell-attendance'
import { CellTaskSubmission } from './cell-task-submission'
import { CellRequestList } from './cell-request-list'
import { HeaderGuardians } from './header-guardians'
import { CellGuardians } from './cell-guardians'
import { CellActions } from './cell-actions'
import { CellProgress } from './cell-progress'
import { CellHouse } from './cell-house'
import { Student } from './types'
import { CellMath } from './cell-math'
import { CellPathways } from './cell-pathways'
import { CellStatus } from './cell-status'

async function getStudentsWithMetrics({ students, timeframe }) {
  const { data: metrics } = await axios.get(`/backoffice/students?_loader=metrics&timeframe=${timeframe}&ids=${students.map(s => s.id).join(',')}`)
  const studentsWithMetrics = students.map(student => {
    const studentWithMetrics = {
      ...student,
      ...metrics[student.id],
    }
    return studentWithMetrics
  })
  return studentsWithMetrics
}

async function getFilters(searchParams) {
  searchParams.set('_loader', 'filters')
  const { data } = await axios.get(`/backoffice/students?${searchParams.toString()}`)
  return data
}

async function loader({ request }: LoaderFunctionArgs) {
  const searchParams = new URL(request.url).searchParams
  const _loader = searchParams.get('_loader')
  const result = await axios.get(`/backoffice/students?${searchParams.toString()}`, { responseType: _loader === 'csv' ? 'blob' : 'json' })
  if (_loader === 'csv') {
    const fileName = result.headers["content-disposition"].split("filename=")[1]
    const contentType = result.headers["content-type"]
    /* @ts-ignore */
    if (window.navigator && window.navigator.msSaveOrOpenBlob) {
      /* @ts-ignore */
      window.navigator.msSaveOrOpenBlob(new Blob([result.data], { type: contentType }), fileName.substring(1, fileName.length - 1))
    } else {
      const url = window.URL.createObjectURL(new Blob([result.data], { type: contentType }))
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', fileName.substring(1, fileName.length - 1))
      document.body.appendChild(link)
      link.click()
    }
    return null
  }
  return {
    ...result.data,
    studentsWithMetrics: getStudentsWithMetrics({ students: result.data.students, timeframe: result.data.query.timeframe }),
    filters: getFilters(searchParams)
  }
}

const columnLabels: { name: string, label: string }[] = [
  { name: 'status', label: 'Status' },
  { name: 'trendAttendanceCount', label: 'Attendance' },
  { name: 'trendCompletedTasksCount', label: 'Task Submission' },
  { name: 'currentHouseTitle', label: 'House' },
  { name: 'graduationStatus', label: 'Progress' },
  { name: 'programProgress', label: 'Program Progress' },
  { name: 'expectedGraduationAt', label: 'Expected Graduation' },
  { name: 'requestListLowestCount', label: 'Request List' },
  { name: 'pathways', label: 'Pathways' },
  { name: 'mathPlacement', label: 'Math Placement' },
  { name: 'language', label: 'Language' },
  { name: 'guardians', label: 'Guardians' },
  { name: 'actions', label: 'Actions' },
]

const initialColumnVisibility: Record<string, boolean> = columnLabels.reduce((acc, column) => {
  acc[column.name] = true
  return acc
}, {
  id: true,
  name: true,
})


type Columns = ColumnDef<Student>[]

const columns: Columns = [
  {
    accessorKey: "id",
    enableColumnFilter: false,
    enableSorting: true,
    header: "ID",
    cell: ({ row }) => <CellId {...row.original} />,
    size: 64,
  },
  {
    accessorKey: "name",
    enableSorting: true,
    enableColumnFilter: true,
    header: ({ table }) => <HeaderStudent table={table} />,
    cell: ({ row }) => <CellStudentName {...row.original} />,
    size: 250,
    meta: {
      filterIsGrouped: true,
      filterIsMultiple: true,
    }
  },
  {
    accessorKey: "status",
    header: "Status",
    enableColumnFilter: true,
    enableSorting: true,
    cell: ({ row }) => <CellStatus {...row.original} />,
    size: 150,
    meta: {
      filterIsGrouped: false,
      filterIsMultiple: true,
    }
  },
  {
    accessorKey: "trendAttendanceCount",
    enableColumnFilter: false,
    header: "Attendance",
    cell: ({ row }) => <CellAttendance {...row.original} />,
  },
  {
    accessorKey: "trendCompletedTasksCount",
    enableColumnFilter: false,
    header: "Task Submission",
    size: 175,
    cell: ({ row }) => <CellTaskSubmission {...row.original} />,
  },
  {
    accessorKey: 'currentHouseTitle',
    enableSorting: true,
    enableColumnFilter: true,
    header: "House",
    cell: ({ row }) => <CellHouse {...row.original} />,
    size: 190,
    meta: {
      filterIsGrouped: true,
      filterIsMultiple: true,
    }
  },
  {
    accessorKey: "graduationStatus",
    enableSorting: true,
    enableColumnFilter: true,
    header: "Progress",
    cell: ({ row }) => <CellProgress {...row.original} />,
    size: 170,
    meta: {
      filterKey: 'graduationStatuses',
      filterIsGrouped: false,
      filterIsMultiple: true,
    }
  },
  {
    accessorKey: "programProgress",
    enableSorting: true,
    enableColumnFilter: false,
    header: "Program Progress",
    cell: info => info.getValue() ? `${info.getValue()}%` : '',
    size: 190,
  },
  {
    accessorKey: "expectedGraduationAt",
    enableSorting: true,
    enableColumnFilter: false,
    header: "Expected Graduation",
    cell: info => info.getValue() ? format(new Date(`${info.getValue()}`), 'MMM d, yyyy') : '',
    size: 220,
  },
  {
    accessorKey: "requestListLowestCount",
    enableSorting: true,
    enableColumnFilter: true,
    header: "Request List",
    cell: ({ row }) => <CellRequestList {...row.original} />,
    size: 190,
    meta: {
      filterIsGrouped: false,
      filterIsMultiple: true,
    }
  },
  {
    accessorKey: "pathways",
    enableColumnFilter: true,
    header: "Pathways",
    cell: ({ row }) => <CellPathways {...row.original} />,
    size: 190,
    meta: {
      filterIsGrouped: true,
      filterIsMultiple: true,
    }
  },
  {
    accessorKey: "mathPlacement",
    enableColumnFilter: true,
    enableSorting: true,
    header: "Math Placement",
    cell: ({ row }) => <CellMath {...row.original} />,
    size: 210,
    meta: {
      filterIsGrouped: false,
      filterIsMultiple: true,
    }
  },
  {
    accessorKey: "language",
    enableColumnFilter: true,
    enableSorting: true,
    header: "Language",
    size: 180,
    meta: {
      filterIsGrouped: false,
      filterIsMultiple: true,
    }
  },
  {
    id: "guardians",
    accessorKey: "guardians[0].name",
    enableColumnFilter: false,
    header: ({ table }) => <HeaderGuardians table={table} />,
    cell: ({ row }) => <CellGuardians {...row.original} />,
    size: 190,
  },
  {
    id: "actions",
    enableColumnFilter: false,
    cell: ({ row }) => <CellActions {...row.original} />,
    size: 50
  },
]

interface StudentsData {
  students: Student[]
  studentsWithMetrics: Student[]
  count: number
  totalPages: number
  currentPage: number
  query: {
    search: string
    timeframe: string
    status: string
    sorting: {
      id: string
      desc: boolean
    }
    columnFilters: { id: string, value: any }[]
  }
  filters: Promise<FiltersData>
}

interface FiltersData {
  houses: {
    title: string
    houses: {
      id: number
      title: string
    }[]
  }[]
  mathPlacements: {
    id: number
    title: string
  }[]
}

function Element() {
  const data = useLoaderData() as StudentsData
  const submit = useSubmit()
  const fetcher = useFetcher()
  let [searchParams] = useSearchParams()
  const navigation = useNavigation()
  const searchInputRef = useRef(null)
  const formData = navigation.formData
  const timeframe = String(formData?.get('timeframe') || searchParams.get('timeframe') || data.query.timeframe)
  const search = String(formData?.get('search') || searchParams.get('search') || data.query.search)
  const sorting = data.query.sorting
  const columnFilters = data.query.columnFilters
  const [columnVisibility, setColumnVisibility] = useLocalStorage('students-column-visibility', initialColumnVisibility)
  const [columnOrder, setColumnOrder] = useLocalStorage('students-current-order', columnLabels.map(({ name }) => name))
  const [dragIndex, setDragIndex] = React.useState<number | null>(null)

  const handleDragStart = (index: number) => {
    setDragIndex(index);
  }

  const handleDragEnter = (index: number) => {
    if (dragIndex !== null) {
      const newOrder = [...columnOrder]
      const [movedItem] = newOrder.splice(dragIndex, 1)
      newOrder.splice(index, 0, movedItem)

      setColumnOrder(newOrder)
      setDragIndex(index)
    }
  }

  const handleDragEnd = () => {
    setDragIndex(null)
  }

  const handleToggleColumnVisibility = (columnName) => {
    if (columnName === 'show_all') {
      return setColumnVisibility(initialColumnVisibility)
    }
    if (columnName === 'hide_all') {
      return setColumnVisibility(columnLabels.reduce((acc, column) => {
        acc[column.name] = false
        return acc
      }, { id: true, name: true }))
    }
    if (columnVisibility[columnName]) {
      setColumnVisibility((prevColumnVisibility) => ({ ...prevColumnVisibility, [columnName]: false }))
    } else {
      setColumnVisibility((prevColumnVisibility) => ({ ...prevColumnVisibility, [columnName]: true }))
    }
  }

  const handleClearSearch = () => {
    searchInputRef.current.value = ''
    const form = searchInputRef.current.closest('form')
    submit(form)
  }

  const handleExport = (e) => {
    e.preventDefault()
    const formData = new FormData(e.target)
    searchParams.entries().forEach(([key, value]) => {
      formData.append(key, value)
    })
    fetcher.submit(formData)
  }

  return (
    <div className="flex flex-col gap-4">
      <header className="relative flex flex-col gap-4">
        <div className="flex justify-between items-center gap-2 my-2 lg:my-0">
          <Typography variant="heading-3" weight="bold">Students</Typography>
        </div>
        <div className="flex gap-4">
          <Form method="get" onChange={e => submit(e.currentTarget)} className="grid grid-cols-1 lg:grid-flow-col lg:auto-cols-fr grow gap-4 max-w-screen-md">
            <TextField
              ref={searchInputRef}
              startAdornment={<Icon name="search" size="sm" />}
              placeholder="Search students..."
              name='search'
              defaultValue={search}
              endAdornment={
                <Button variant="ghost" size="sm" className="text-alpha/40" type="button" onClick={handleClearSearch}>
                  <Icon name="cross-circle" />
                </Button>
              }
              autoComplete='off'
              fullWidth
              autoFocus
            />
            {(columnVisibility.trendAttendanceCount || columnVisibility.trendCompletedTasksCount) && (
              <Select name="timeframe" defaultValue={timeframe}>
                <Select.Trigger className="w-full">
                  <Select.Value placeholder={`Attendance, Tasks: ${timeframe === 'currentCycle' ? 'Current Cycle' : 'Last 7 Days'}`}>
                    <div className="whitespace-nowrap text-ellipsis overflow-hidden">
                      Attendance, Tasks: {timeframe === 'currentCycle' ? 'Current Cycle' : 'Last 7 Days'}
                    </div>
                  </Select.Value>
                </Select.Trigger>
                <Select.Content>
                  <Select.Group>
                    <Select.Label>Timeframe for Attendance and Tasks</Select.Label>
                    <Select.Item value="lastSevenDays">Last 7 Days</Select.Item>
                    <Select.Item value="currentCycle">Current Cycle</Select.Item>
                  </Select.Group>
                </Select.Content>
              </Select>
            )}
          </Form>
          <div className="flex gap-2 absolute top-0.5 right-0 lg:static">
            <DropdownMenu>
              <Tooltip content="Customize columns">
                <DropdownMenu.Trigger asChild>
                  <Button color="soft" variant="outlined" size="lg" type="button">
                    <Icon name="gear" size="md" />
                  </Button>
                </DropdownMenu.Trigger>
              </Tooltip>
              <DropdownMenu.Content align="end">
                <DropdownMenu.Group>
                  <DropdownMenu.Label className="flex justify-between gap-6">
                    <span className="w-full mr-auto">Column Visibility</span>
                    <div className="font-normal whitespace-nowrap flex gap-1">
                      <button className="text-blue-40 hover:text-blue-60 dark:text-blue-20 dark:hover:text-blue-10 underline" onClick={() => handleToggleColumnVisibility('show_all')}>Show all</button>
                      /
                      <button className="text-blue-40 hover:text-blue-60 dark:text-blue-20 dark:hover:text-blue-10 underline" onClick={() => handleToggleColumnVisibility('hide_all')} >Hide all</button>
                    </div>
                  </DropdownMenu.Label>
                  {columnOrder.map((name, index) => (
                    <DropdownMenu.SwitchItem
                      key={name}
                      checked={columnVisibility[name]}
                      onToggle={() => handleToggleColumnVisibility(name)}
                      onSelect={e => e.preventDefault()}
                      draggable
                      onDragStart={() => handleDragStart(index)}
                      onDragEnter={() => handleDragEnter(index)}
                      onDragEnd={handleDragEnd}
                    >
                      <Icon name="reorder" size="sm" className="text-gray-50 -ml-1 mr-1 cursor-move" />
                      {columnLabels.find(({ name }) => name === columnOrder[index])?.label}
                    </DropdownMenu.SwitchItem>
                  ))}
                </DropdownMenu.Group>
              </DropdownMenu.Content>
            </DropdownMenu>
            <fetcher.Form onSubmit={handleExport}>
              <>
                <input type="hidden" name="_loader" value="csv" />
                <Tooltip content="Export">
                  <Button
                    name="_loader"
                    value="csv"
                    color="soft"
                    variant="outlined"
                    size="lg"
                    type="submit"
                    loading={fetcher.state !== 'idle'}
                  >
                    <Icon name="file-download" size="md" />
                  </Button>
                </Tooltip>
              </>
            </fetcher.Form>
          </div>
        </div>
      </header>
      <React.Suspense fallback={<DataTable columns={columns} data={data.students} filtersFn={data.filters} columnVisibility={columnVisibility} columnOrder={columnOrder} initialSorting={sorting} initialColumnFilters={columnFilters} />}>
        <Await
          resolve={data.studentsWithMetrics}
          errorElement={
            <p>Error loading students!</p>
          }
        >
          {(studentsWithMetrics) => (
            <DataTable filtersFn={data.filters} columns={columns} data={studentsWithMetrics} columnVisibility={columnVisibility} columnOrder={columnOrder} initialSorting={sorting} initialColumnFilters={columnFilters} />
          )}
        </Await>
      </React.Suspense>
      <Pagination totalPages={data.totalPages} currentPage={data.currentPage} />
      <Typography className="text-center">Students found: {data.count}</Typography>
      <Outlet />
    </div>
  )
}

export const StudentsRoute = {
  loader,
  Element,
}
