import React, { Fragment, useEffect, useRef, useState } from 'react'
import axios from 'axios'
import uniqueId from 'lodash/uniqueId'
import { Form, Link, Outlet, useLoaderData, useOutletContext, useParams, useSubmit } from 'react-router'
import Tooltip from '@components/tooltip'
import Paginator from '@components/paginator'
import { TextField } from '@designsystem'
import TableEmptyState from './empty-state'
import useConfirmModal from '@hooks/useConfirmModal'
import serializeFormData from '@utils/serializeFormData'
import { useThrottle } from '@hooks/useThrottle'
import { useCampusData } from '@features/workbench.onboarding.families'
import TableSortingButtons from './sorting-buttons'
import getLocationFullName from './get-location-full-name'
import formatDateForOnboarding from '@components/onboarding/utils/formatDateForOnboarding'
import OnboardingStatusTag, { ONBOARDING_STATUS } from './status-tag'
import { clearAllNotAllowedValuesFromForm } from './clear-not-allowed-values-from-form'
import { Icon, Button, Typography, unstable_Select as Select, useFetcher } from '@design-system'

enum Actions {
  PARENT_TECH_WELCOME_EMAIL = 'parent-tech-welcome',
  FAMILIES_SUMMER_INFO_EMAIL = 'families-summer-info',
}

const NOT_ALLOWED_SELECT_VALUE = 'NOT_ALLOWED' as const

const STATIC_ONBOARDING_STATUS_OPTIONS = {
  placeholder: { value: '', label: 'Select Onboarding Status' },
  clear: { value: NOT_ALLOWED_SELECT_VALUE, label: 'Any Onboarding Status' },
} as const

const STATIC_SCHOOL_START_DATE_OPTIONS = {
  placeholder: { value: '', label: 'School Start Date' },
  clear: { value: NOT_ALLOWED_SELECT_VALUE, label: 'Any School Start Date' },
} as const

export interface StudentInfo {
  id: number,
  name: string,
  grade: string,
  state: string,
  campus: string,
  gender: string,
  soraEmail: string,
  startDate?: string,
  transcriptStatus?: string,
  onboardingGroupId?: number,
  lastCompletedChecklist?: string,
  onboardingStatus: ONBOARDING_STATUS,
  welcomeEmailsReceived: boolean,
  schoolDirectorEmailReceived: boolean,
  guardianStatuses: Array<{
    name: string,
    status: ONBOARDING_STATUS,
    lastCompletedChecklist: string,
  }>,
  guardians: Array<{
    id: number,
    name: string,
    email: string,
  }>
}

interface LoaderData {
  families: StudentInfo[],
  currentPage: number,
  totalPages: number,
  statuses: Array<{ value: string, label: string }>,
  startDates: Array<{ value: string, label: string }>,
}

type ActionFormData = {
  families: string,
  actionName: Actions.PARENT_TECH_WELCOME_EMAIL | Actions.FAMILIES_SUMMER_INFO_EMAIL,
}

export function useSelectedFamilies() {
  return useOutletContext<[StudentInfo[], React.Dispatch<React.SetStateAction<StudentInfo[]>>]>()
}

async function loader({ params, request }) {
  const url = new URL(request.url)
  const searchParams = url.searchParams
  const res = await axios.get(`/backoffice/workbench/onboarding/families/${params.campus_filter}`, {
    params: searchParams
  })
  return res.data
}


async function action({ request, params }) {
  const formData = serializeFormData(await request.formData()) as ActionFormData

  if (formData.actionName === Actions.PARENT_TECH_WELCOME_EMAIL || formData.actionName === Actions.FAMILIES_SUMMER_INFO_EMAIL) {
    try {
      await axios.post(`/backoffice/workbench/onboarding/families/${params.campus_filter}`, { families: formData.families, action: formData.actionName })
      const toastMessage = formData.actionName === Actions.PARENT_TECH_WELCOME_EMAIL
        ? 'Sending emails and creating onboarding for families. You will be notified when it is done.'
        : 'Sending email for families. You will be notified when it is done.'
      return {
        resetSelectedFamilies: true,
        toast: {
          message: toastMessage,
          appearance: 'success',
        }
      }
    } catch (error) {
      return {
        toast: {
          message: 'There was an error during this operation. Try again.',
          appearance: 'error',
        }
      }
    }
  }

  return null
}

function Element() {
  const params = useParams()

  const campusData = useCampusData()
  const confirmModal = useConfirmModal()
  const [ordination, setOrdination] = useState('')
  const sendOnboardingEmailsFetcher = useFetcher()
  const [selectedFamilies, setSelectedFamilies] = useState<StudentInfo[]>([])
  const { families, currentPage, totalPages, startDates, statuses } = useLoaderData() as LoaderData

  const isFamiliesEmpty = !families || !families.length
  const isUnassignedTable = Number(params.campus_filter) === -1
  const isAllOrUnassignedTable = isUnassignedTable || Number(params.campus_filter) === 0
  const allFamiliesHaveGroups = selectedFamilies.every((family) => family.onboardingGroupId)
  const familiesHaveGroupsAndPageIsNotInAllOrUnassigned = !isAllOrUnassignedTable && allFamiliesHaveGroups

  useEffect(() => {
    setSelectedFamilies([])
  }, [params.campus_filter])

  useEffect(() => {
    if (sendOnboardingEmailsFetcher.data?.resetSelectedFamilies) setSelectedFamilies([])
  }, [sendOnboardingEmailsFetcher.data?.resetSelectedFamilies])

  const toggleFamily = (incomingFamily: StudentInfo) => {
    setSelectedFamilies((prevState) => {
      return prevState.map(({ id }) => id).includes(incomingFamily.id)
        ? [...prevState.filter(({ id }) => id !== incomingFamily.id)]
        : [...prevState, incomingFamily]
    })
  }

  const handleSendParentTechEmail = async (families: StudentInfo[]) => {
    if (!await confirmModal({
      title: 'Are you ready to send parent tech welcome email?',
      subtitle: 'The families you have selected will receive an email with a the sora home login information.'
    })) return
    const formData = new FormData()
    formData.append('actionName', Actions.PARENT_TECH_WELCOME_EMAIL)
    formData.append('families', JSON.stringify(families))
    sendOnboardingEmailsFetcher.submit(formData, { method: 'POST' })
  }

  return (
    <div className="flex flex-col space-y-8 mt-8">
      <TableFilter
        statuses={statuses}
        startDates={startDates}
        ordination={ordination}
        isUnassignedTable={isUnassignedTable}
      />
      <div className="overflow-x-auto relative rounded-lg border border-gray-20">
        <table className="w-full bg-white">
          <thead className="border-b border-gray-20">
            <tr>
              <td colSpan={9} className="border-b border-gray-20 px-4">
                {!selectedFamilies.length
                  ? <Typography variant="body" className="h-7 my-4 self-center">Select a student to assign a campus or send invitation to start onboarding.</Typography>
                  : (
                    <div className="h-7 my-4 items-center flex justify-between">
                      <Typography variant="body">{`${selectedFamilies.length} families selected`}</Typography>
                      <div className="flex space-x-6">
                        <Button
                          variant="outlined"
                          asChild
                        >
                          <Link
                            to="./add-families-to-group"
                          >
                            <Icon name="folder"></Icon>
                            {params.campus_filter === '-1' ? 'Assign a Campus' : 'Change Campus'}
                          </Link>
                        </Button>
                        <div data-for="startOnboardingTooltip" data-tip="Campus and start date are required to start onboarding.">
                          <sendOnboardingEmailsFetcher.Form method="POST">
                            <Button
                              variant="outlined"
                              disabled={!familiesHaveGroupsAndPageIsNotInAllOrUnassigned}
                              onClick={familiesHaveGroupsAndPageIsNotInAllOrUnassigned ? () => handleSendParentTechEmail(selectedFamilies) : undefined}
                            >
                              <Icon name="email"></Icon>
                              Parent Tech Welcome
                            </Button>
                          </sendOnboardingEmailsFetcher.Form>
                          <Tooltip id="startOnboardingTooltip" place="top" disable={familiesHaveGroupsAndPageIsNotInAllOrUnassigned} isFromPortal={true} />
                        </div>
                      </div>
                    </div>
                  )}
              </td>
            </tr>
            <tr>
              <th className="text-left py-4 pl-4 pr-3">
                <input
                  type="checkbox"
                  name="select_all_families"
                  checked={selectedFamilies.length === families.length && selectedFamilies.length !== 0}
                  onChange={() => {
                    if (selectedFamilies.length === families.length) {
                      setSelectedFamilies([])
                    } else {
                      setSelectedFamilies(families)
                    }
                  }} />
              </th>
              <th className="text-left py-4 pr-3">
                <div className="flex items-center space-x-2">
                  <Typography variant="body">Students</Typography>
                  <TableSortingButtons
                    currentSortingValue={ordination}
                    sortingValues={['student_asc', 'student_desc']}
                    handleClick={() => (value: string) => setOrdination(value)}
                  />
                </div>
              </th>
              <th className="text-left py-4 px-3"><Typography variant="body">Guardians</Typography></th>
              <th className="text-left py-4 px-3">
                <div className="flex space-x-2 items-center">
                  <Typography variant="body">Grade</Typography>
                  <TableSortingButtons
                    currentSortingValue={ordination}
                    sortingValues={['grade_asc', 'grade_desc']}
                    handleClick={() => (value: string) => setOrdination(value)}
                  />
                </div>
              </th>
              <th className="text-left py-4 px-3"><Typography variant="body">Campus</Typography></th>
              <th className="text-left py-4 px-3">
                <div className="flex space-x-2 items-center">
                  <Typography variant="body">Student Status</Typography>
                  <TableSortingButtons
                    currentSortingValue={ordination}
                    sortingValues={['status_asc', 'status_desc']}
                    handleClick={() => (value: string) => setOrdination(value)}
                  />
                </div>
              </th>
              <th className="text-left py-4 px-3">
                <Typography variant="body">Guardian Status</Typography>
              </th>
              <th className="text-left py-4 px-3">
                <div className="flex space-x-2 items-center">
                  <Typography variant="body">School Start</Typography>
                  <TableSortingButtons
                    currentSortingValue={ordination}
                    sortingValues={['date_asc', 'date_desc']}
                    handleClick={() => (value: string) => setOrdination(value)}
                  />
                </div>
              </th>
              <th className="text-left py-4 pr-4 pl-3">
                <Typography variant="body">Emails Status</Typography>
              </th>
            </tr>
          </thead>
          <tbody>
            {!isFamiliesEmpty
              ? families.map((student) => (
                <tr key={`student_${student.id}_${uniqueId()}`} className="border-b border-gray-20">
                  <td className="p-4 flex items-start">
                    <input
                      type="checkbox"
                      name="checkFamily"
                      checked={!!selectedFamilies.find(({ id }) => id === student.id)}
                      onChange={() => toggleFamily(student)} />
                  </td>
                  <td className="py-4">
                    <span className="flex w-fit max-w-[200px] flex-col space-y-1">
                      <Typography variant="body" weight="bold" className="truncate" title={student.name}>{student.name}</Typography>
                      <Typography variant="footnote" className="truncate capitalize" title={student.gender}>{student.gender}</Typography>
                      <Typography variant="footnote" className="truncate" title={student.soraEmail}>{student.soraEmail}</Typography>
                      <div className="flex space-x-1 items-center truncate">
                        <Icon name="pin" size="xs"></Icon>
                        <Typography variant="footnote">{getLocationFullName(student.state)}</Typography>
                      </div>
                    </span>
                  </td>
                  <td className="p-4">
                    <span className="flex flex-col space-y-1">
                      {student.guardians.map((guardian) => (
                        <Fragment key={`guardian_${guardian.id}`}>
                          <Typography variant="body" weight="bold">{guardian.name}</Typography>
                          <Typography variant="footnote">{guardian.email}</Typography>
                        </Fragment>
                      ))}
                    </span>
                  </td>
                  <td className="p-4"><Typography variant="body">{student.grade}</Typography></td>
                  <td className="p-4"><Typography variant="body">{student.campus ?? '-'}</Typography></td>
                  <td className="p-4">
                    <OnboardingStatusTag
                      status={student.onboardingStatus}
                      lastCompletedChecklist={student.lastCompletedChecklist}
                    />
                  </td>
                  <td className="p-4">
                    <div className="flex flex-col space-y-2 justify-start">
                      {student.guardianStatuses.map(({ name, status, lastCompletedChecklist }) => (
                        <div key={`guardian_${name}`} className="flex flex-col space-y-1">
                          <Typography variant="body" weight="bold">{name}</Typography>
                          <OnboardingStatusTag status={status} lastCompletedChecklist={lastCompletedChecklist} />
                        </div>
                      ))}
                    </div>
                  </td>
                  <td className="p-4">
                    {student.startDate
                      ? formatDateForOnboarding(student.startDate)
                      : <span className="justify-center flex"><Typography variant="body">-</Typography></span>}
                  </td>
                  <td className="p-4">
                    <div className="flex items-start flex-col space-y-1 truncate">
                      <div className="space-x-1 flex items-center">
                        <i className={`w-2 h-2 rounded-full ${student.welcomeEmailsReceived ? 'bg-green-40' : 'bg-orange-40'}`} />
                        <Typography variant="footnote">Tech Welcome Email</Typography>
                      </div>
                      <div className="space-x-1 flex items-center">
                        <i className={`w-2 h-2 rounded-full ${student.schoolDirectorEmailReceived ? 'bg-green-40' : 'bg-orange-40'}`} />
                        <Typography variant="footnote">Summer Info Email</Typography>
                      </div>
                    </div>
                  </td>
                </tr>
              ))
              : <TableEmptyState campusId={params.campus_filter} campusData={campusData} />}
          </tbody>
          {!isFamiliesEmpty
            ? (
              <tfoot>
                <tr>
                  <td colSpan={9} className="text-center p-4">
                    <Paginator
                      formId="onboarding-table"
                      totalPages={totalPages}
                      currentPage={currentPage}
                    />
                  </td>
                </tr>
              </tfoot>
            )
            : null}
        </table >
      </div >
      <Outlet context={[selectedFamilies, setSelectedFamilies]} />
    </div >
  )
}

interface TableFilterProps {
  statuses: { label: string, value: string }[],
  startDates: { label: string, value: string }[],
  isUnassignedTable: boolean,
  ordination: string,
}

function TableFilter({ statuses, startDates, isUnassignedTable, ordination }: TableFilterProps) {
  const submit = useSubmit()
  const params = useParams()
  const formRef = useRef<HTMLFormElement>(null)
  const allStatuses = [STATIC_ONBOARDING_STATUS_OPTIONS.clear, ...statuses]
  const allStartDates = [STATIC_SCHOOL_START_DATE_OPTIONS.clear, ...startDates]
  const [searchValue, setSearchValue] = useState('')
  const trothledFilterValue = useThrottle(searchValue, 400)
  // since there's no way to pass a ref to a radix select, we need to force a re-render to reset the select state https://github.com/radix-ui/primitives/issues/1569
  const [studentStatusSelectKey, setStudentStatusSelectKey] = useState(uniqueId())
  const [startDateSelectKey, setStartDateSelectKey] = useState(uniqueId())

  useEffect(() => {
    if (formRef.current) {
      formRef.current.reset()
      setStudentStatusSelectKey(uniqueId())
      setStartDateSelectKey(uniqueId())
    }
  }, [params.campus_filter])

  useEffect(() => {
    if (formRef.current && ordination) {
      const formData = clearAllNotAllowedValuesFromForm(new FormData(formRef.current), NOT_ALLOWED_SELECT_VALUE)
      submit(formData)
    }
  }, [ordination])

  useEffect(() => {
    formRef.current && trothledFilterValue && submit(clearAllNotAllowedValuesFromForm(new FormData(formRef.current), NOT_ALLOWED_SELECT_VALUE))
  }, [trothledFilterValue])

  return (
    <Form id="onboarding-table" ref={formRef} className="grid grid-cols-2" method="GET">
      <input type="hidden" name="order_by" value={ordination} />
      <TextField
        id="search-input"
        fullWidth
        name="search"
        onChange={(e) => setSearchValue(e.target.value)}
        placeholder="Search for student or guardian name"
      />
      <div className="ml-12 flex space-x-4">
        <Select key={studentStatusSelectKey} name="student_status" onValueChange={() => {
          const formData = clearAllNotAllowedValuesFromForm(new FormData(formRef.current), NOT_ALLOWED_SELECT_VALUE)
          submit(formData)
        }}>
          <Select.Trigger disabled={isUnassignedTable} className="h-12">
            <Select.Value placeholder="Select Student Onboarding Status">
            </Select.Value>
          </Select.Trigger>
          <Select.Content>
            {allStatuses.map((status) => (
              <Select.Item value={status.value} key={status.value}>
                {status.label}
              </Select.Item>
            ))}
          </Select.Content>
        </Select>
        <Select key={startDateSelectKey} name="start_date" onValueChange={() => {
          const formData = clearAllNotAllowedValuesFromForm(new FormData(formRef.current), NOT_ALLOWED_SELECT_VALUE)
          submit(formData)
        }}>
          <Select.Trigger disabled={isUnassignedTable} className="h-12">
            <Select.Value placeholder="Select Start Date">
            </Select.Value>
          </Select.Trigger>
          <Select.Content>
            {allStartDates.map((startDate) => (
              <Select.Item value={startDate.value} key={startDate.value}>
                {startDate.label}
              </Select.Item>
            ))}
          </Select.Content>
        </Select>
      </div>
    </Form >
  )
}

export const WorkbenchOnboardingFamiliesReportFilterRoute = { Element, action, loader }