import React, { PropsWithChildren } from 'react'
import { toast } from 'sonner'
import { Button, Icon, unstable_Tooltip as Tooltip } from '@design-system'
import useClipboard from '@hooks/useClipboard'

interface CopyButtonProps extends PropsWithChildren {
  content: string
  className?: string
  tooltip?: string
}

export const CopyButton = ({ content, children, className, tooltip }: CopyButtonProps) => {
  const [, copy] = useClipboard()
  const handleCopy = () => {
    try {
      copy(content)
      toast.success('Copied')
    } catch (e) {
      toast.error('Something went wrong')
    }
  }
  return (
    <div className="group/copy flex items-center gap-2">
      <div className={className}>
        {children}
      </div>
      <Tooltip content={tooltip ?? "Copy"}>
        <Button onClick={handleCopy} size="xs" variant="ghost" className="hidden group-hover/table:flex group-hover/copy:flex">
          <Icon name="email" size="xs" />
        </Button>
      </Tooltip>
    </div>
  )
}
