import React from 'react'
import axios from 'axios'
import { useLoaderData, ActionFunctionArgs, useParams, Outlet, redirect, useLocation } from 'react-router'
import serializeFormData from '@utils/serializeFormData'
import { Alert, Button, Card, DropdownMenu, Icon, Typography, useFetcher } from '@design-system'
import SoraLink from '@components/link'
import useNavigateWithCycle from '@hooks/useNavigateWithCycle'

interface LoaderData {
  schoolStages: {
    value: string
    label: string
  }[]
  alert?: {
    variant?: 'notice'
    title: string
    description?: string
    action?: string
    label?: string
  },
  subjects: {
    id: number
    title: string
    creditDiff: number
  }[]
  totalUnits: number
}

export async function loader({ params }) {
  const { data } = await axios.get(`/backoffice/employee/students/${params.studentId}/program/${params.schoolStage ?? ''}`)
  if (!params.schoolStage) {
    return redirect(`/employee/students/${params.studentId}/program/${data.schoolStage}/overview`)
  }
  return data
}

async function action({ request, params }: ActionFunctionArgs) {
  const formData = await request.formData()
  const { data } = await axios.post(`/backoffice/employee/students/${params.studentId}/program/${params.schoolStage}`, serializeFormData(formData))
    .catch(error => {
      return {
        data: {
          toast: {
            appearance: 'error',
            message: error.response.data.error.message
          },
          errors: error.response.data.errors,
        },
      }
    })
  return data
}

function Element() {
  const { schoolStages, alert, subjects, totalUnits } = useLoaderData() as LoaderData
  const navigate = useNavigateWithCycle()
  const { studentId, schoolStage, subjectId } = useParams()
  const location = useLocation()
  const currentTab = subjectId ?? location.pathname.split('/').pop()
  const fetcher = useFetcher()
  const handleChange = (value) => {
    navigate(`/employee/students/${studentId}/program/${value}/overview`)
  }
  return (
    <div className="space-y-8">
      {schoolStages.length > 1 ? (
        <div className="-ml-5">
          <DropdownMenu>
            <DropdownMenu.Trigger asChild>
              <Button variant="ghost" disabled={schoolStages.length === 1}>
                {schoolStages.find(st => st.value === schoolStage)?.label} program ({totalUnits} units)
                <Icon name="chevron-down" />
              </Button>
            </DropdownMenu.Trigger>
            <DropdownMenu.Content align="end">
              <DropdownMenu.RadioGroup value={schoolStage} onValueChange={handleChange}>
                {schoolStages.map(item => (
                  <DropdownMenu.RadioItem key={item.value} value={item.value}>
                    {item.label}
                  </DropdownMenu.RadioItem>
                ))}
              </DropdownMenu.RadioGroup>
            </DropdownMenu.Content>
          </DropdownMenu>
        </div>
      ) : <Typography variant="heading-6" weight="bold">{schoolStages.find(st => st.value === schoolStage)?.label} program ({totalUnits} units)</Typography>}
      {alert && (
        <Alert variant={alert.variant}>
          <Alert.Title>{alert.title}</Alert.Title>
          {alert.description && (
            <Alert.Description>{alert.description}</Alert.Description>
          )}
          {alert.action && (
            <Alert.Actions>
              <fetcher.Form method="POST">
                <input type="hidden" name="_action" value={alert.action} />
                <Button size='xs' type="submit">
                  {alert.label}
                </Button>
              </fetcher.Form>
            </Alert.Actions>
          )}
        </Alert>
      )}
      <nav className="flex gap-2 flex-wrap">
        {subjects.map(subject => (
          <Button key={subject.id} size="xs" color={String(subject.id) === String(currentTab) ? "dark" : "soft"} asChild>
            <SoraLink to={`${subject.id}`}>
              {subject.title}
              {subject.creditDiff < 0 && <Icon name="alert-triangle" size="xs" />}
            </SoraLink>
          </Button>
        ))}
      </nav>
      <Outlet />
      <Card>
        <Card.Content padding="sm" className="flex justify-between items-center">
          <Typography>Looking for more info about programs and exemptions?</Typography>
          <Button variant="ghost" size="sm" asChild>
            <a href="https://support.soraschools.com/en/articles/9828631-how-transfer-credit-exemptions-work-at-sora" target="_blank" rel="noopener noreferrer">
              Go to Knowledge Hub
            </a>
          </Button>
        </Card.Content>
      </Card>
    </div>
  )
}

export const EmployeeStudentProgramRoute = {
  Element,
  loader,
  action
}


