import * as React from 'react'
import SoraLink from '@components/link'
import { Card, Icon, Typography } from '@design-system'
import { TextOnlySlate } from '@components/forms/slate-textarea'

type NoteEvent = {
  type: 'note',
  content: {
    noteType: string,
    noteTableLink: string,
  }
}

type InformationUpdateEvent = {
  type: 'information_update',
  content: { contentType: 'rich_text' | 'text', content: string }[]
}

type SuspensionUpdateEvent = {
  type: 'suspension_update',
  content: string
}

type ExemptionUpdateEvent = {
  type: 'exemption_update',
  content: string[]
}

type BaseRefactoredTimelineEvent = {
  id: number
  title: string
  createdAt: Date
  authorName: string
}

export type TimelineEvent = BaseRefactoredTimelineEvent & NoteEvent | BaseRefactoredTimelineEvent & InformationUpdateEvent | BaseRefactoredTimelineEvent & SuspensionUpdateEvent | BaseRefactoredTimelineEvent & ExemptionUpdateEvent

/**
  * Student Timeline Card that represents TimelineEvents
*/
export function CardStudentTimeline({ events }: { events: TimelineEvent[] }) {
  return (
    <aside className="w-full md:max-w-48 lg:max-w-80">
      <Card>
        <Card.Content padding="xs">
          <Typography weight="bold" className="p-4">Timeline</Typography>
          {events.length > 0 && (
            events.map((event) => <Event event={event} key={event.id} />)
          )}
        </Card.Content>
      </Card>
    </aside>
  )
}

function Event({ event }: { event: TimelineEvent }) {
  return (
    <div key={event.id} className="p-4 border-t border-t-gray-30 space-y-1">
      <div className="flex flex-row justify-between">
        <Typography weight="bold">{event.title}</Typography>
        <Typography variant="footnote" color="tertiary" className="text-nowrap">
          {new Date(event.createdAt).toLocaleDateString([], {
            month: 'short',
            day: 'numeric',
            year: '2-digit',
          })}
        </Typography>
      </div>
      <Typography variant="callout">Updated by: {event.authorName}</Typography>
      <div className="flex flex-col">
        <TimelineEventContentRefactored event={event} />
      </div>
    </div>
  )
}

function TimelineEventContentRefactored({ event }: { event: TimelineEvent }) {
  switch (event.type) {
    case 'note':
      return <NoteEventContent content={event.content} />
    case 'information_update':
      return <InformationUpdateEventContent content={event.content} />
    case 'suspension_update':
      return <SuspensionUpdateEventContent content={event.content} />
    case 'exemption_update':
      return <ExemptionUpdateEventContent content={event.content} />
    default:
      return null
  }
}

function NoteEventContent({ content }: { content: NoteEvent['content'] }) {
  return (
    <>
      <Typography variant="callout">Type: {content.noteType}</Typography>
      <Typography variant="callout" color="accent" asChild>
        <SoraLink to={content.noteTableLink} reloadDocument>
          View in Table <Icon name="arrow-top-right" size="3xs" />
        </SoraLink>
      </Typography>
    </>
  )
}

function InformationUpdateEventContent({ content }: { content: InformationUpdateEvent['content'] }) {
  return content.map((row) => (
    row.contentType === 'rich_text'
      ?
      // @ts-expect-error TextOnlySlate is not typed, so TS will yell at us
      <TextOnlySlate value={row.content} />
      : <Typography variant="callout">{row.content}</Typography>
  ))
}

function SuspensionUpdateEventContent({ content }: { content: SuspensionUpdateEvent['content'] }) {
  return <Typography variant="callout">{content}</Typography>
}

function ExemptionUpdateEventContent({ content }: { content: ExemptionUpdateEvent['content'] }) {
  return content.map((row, i) => <Typography key={i} variant="callout">{row}</Typography>)
}