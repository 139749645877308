import SlateTextarea from '@components/forms/slate-textarea'
import useConfirmModal from '@hooks/useConfirmModal'
import React, { useEffect, useRef, useState } from 'react'
import { Form, useActionData, useLoaderData, useNavigation, useSubmit } from 'react-router'
import PropTypes from 'prop-types'
import { Button, TextField } from '@designsystem'
import serializeFormData from '@utils/serializeFormData'
import axios from 'axios'
import { useToast } from '@hooks/useToast'


export default function AdmissionsCopyConfig() {
  const config = useLoaderData()
  const confirm = useConfirmModal()
  const submit = useSubmit()
  const [editing, setEditing] = useState(false)
  const actionData = useActionData()
  const loading = useNavigation().state !== 'idle'
  useToast(actionData)

  useEffect(() => {
    if (actionData?.success) {
      setEditing(false)
    }
  }, [actionData])


  return (
    <div className='gap-9'>
      <Form
        className='max-w-[600px] border p-4 rounded-lg flex flex-col gap-2'
        method="POST"
        onSubmit={async (e) => {
          e.preventDefault()
          const form = e.currentTarget
          if (await confirm('Are you sure you want to apply the changes?')) {
            submit(form)
          }
        }}>
        <h2 className='font-bold text-2xl mb-3'>Application summary</h2>
        <ViewOrEditTextField
          value={config.bannerExtraTitle}
          label='Banner extra title'
          name='banner_extra_title'
          editing={editing}
        />
        <ViewOrEditTextField
          value={config.bannerSubtitle}
          label='Banner subtitle'
          name='banner_subtitle'
          editing={editing}
        />
        <ViewOrEditTextArea
          value={config.welcomeMessage}
          label='Welcome message'
          name='welcome_message'
          editing={editing}
        />
        <ViewOrEditTextArea
          value={config.flexibleTuitionAlertContent}
          label='Flexible tuition alert content'
          name='flexible_tuition_alert_content'
          editing={editing}
        />
        <ViewOrEditTextArea
          value={config.ssat}
          label='SSAT or Character Skills Snapshot'
          name='ssat'
          editing={editing}
        />
        <div className='flex justify-end'>
          {
            editing ? <>
              <div className='mr-4'>
                <Button
                  variant='outlined'
                  onClick={() => setEditing(false)}
                >
                  Cancel
                </Button>
              </div>
              <Button type='submit' loading={loading}>
                Apply changes
              </Button>
            </> :
              <Button onClick={
                () => setEditing(true)
              } >
                Edit
              </Button>
          }
        </div>
      </Form>
    </div>
  )
}

const ViewOrEditTextField = ({ value, label, name, editing }) => {
  const ref = useRef()

  return (
    <label
      htmlFor={name}
      onClick={() => ref.current.focus()}
    >
      <h3 className='text-sm font-bold mb-2'>{label}</h3>
      {editing ?
        <TextField
          defaultValue={value}
          name={name}
          ref={ref}
          aria-label={name}
          placeholder='Type here...'
        /> :
        <p className='border p-3 rounded-lg'>{value}</p>
      }
    </label>
  )
}

ViewOrEditTextField.propTypes = {
  value: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  editing: PropTypes.bool.isRequired,
}

const ViewOrEditTextArea = ({ value, label, name, editing }) => {
  const ref = useRef()

  return (
    <label
      htmlFor={name}
      onClick={() => ref.current.focus()}
    >
      <h3 className='text-sm font-bold mb-2'>{label}</h3>
      <SlateTextarea
        value={value}
        name={name}
        ref={ref}
        aria-label={name}
        placeholder='Type here...'
        noAttachments
        readOnly={!editing}
      />
    </label >
  )
}

ViewOrEditTextArea.propTypes = {
  value: PropTypes.object.isRequired,
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  editing: PropTypes.bool.isRequired,
}

export const loader = async ({ request }) => {
  const { data } = await axios.get('/admissions-config')
  return data
}

export const action = async ({ request }) => {
  const formData = await request.formData()
  const serializedFormData = serializeFormData(formData)

  try {
    await axios.post('/admissions-config', serializedFormData)
  } catch (error) {
    return {
      toast: { message: 'There was an error updating the admissions config', appearance: 'error' }
    }
  }

  return {
    toast: { message: 'Admissions config updated', appearance: 'success' },
    success: true
  }
}
