import React, { useEffect, useState } from 'react'
import axios from 'axios'
import { toast } from 'sonner'
import { useFetcher, useLoaderData, useNavigate } from 'react-router'
import { Button } from '@designsystem'
import useConfirmModal from '@hooks/useConfirmModal'
import serializeFormData from '@utils/serializeFormData'
import OnboardingListbox from '@components/onboarding/listbox'
import { StudentInfo, useSelectedFamilies } from '@features/workbench.onboarding.families.$campusFilter'
import { Dialog, Typography } from '@design-system'

export async function loader({ params }) {
  const res = await axios.get(`/pages/admin/workbench/onboarding/families/${params.campus_filter}/add-families-to-group`)
  return res.data
}

export async function action({ request, params }) {
  const formData = await request.formData()
  const serializedData = serializeFormData(formData)
  try {
    const res = await axios.post(`/pages/admin/workbench/onboarding/families/${params.campus_filter}/add-families-to-group`, serializedData)
    const url = new URL(request.url)
    return {
      resetSelectedFamilies: true,
      toast: { message: `Selected families sucessfully added to ${res.data} campus.`, appearance: 'success' },
      redirectUrl: `/workbench/onboarding/families/${params.campus_filter}?${url.search}`
    }
  } catch (error) {
    console.error(error)
    return {
      toast: {
        message: 'There was an error during this operation. Try again.',
        appearance: 'error',
      }
    }
  }
}

export interface SelectOption {
  value: number,
  label: string,
}

export default function WorkbenchOnboardingAddFamiliesToGroupRoute() {
  const fetcher = useFetcher()
  const navigate = useNavigate()
  const confirm = useConfirmModal()
  const [selectedFamilies, setSelectedFamilies] = useSelectedFamilies()
  const { campus, cycles } = useLoaderData() as { campus: SelectOption[], cycles: SelectOption[] }
  const [formSelectState, setFormSelectState] = useState({
    campus: { value: 0, label: 'Select a campus' },
    cycle: { value: 0, label: 'Select a cycle' },
  })

  useEffect(() => {
    if (fetcher.data?.resetSelectedFamilies) setSelectedFamilies([])
  }, [fetcher.data?.resetSelectedFamilies])

  useEffect(() => {
    if (fetcher.data?.redirectUrl) {
      navigate(fetcher.data.redirectUrl)
    }
  }, [fetcher.data?.redirectUrl])

  useEffect(() => {
    if (fetcher.data?.toast) {
      toast[fetcher.data.toast.appearance](fetcher.data.toast.message)
    }
  }, [fetcher.data?.toast])

  const handleConfirmGroupChange = async (e: React.FormEvent<HTMLFormElement>) => {
    const familiesWithOngoingOnboarding = selectedFamilies.filter((family) =>
      family.onboardingStatus === 'in_progress'
      || family.onboardingStatus === 'completed'
      || family.onboardingStatus === 'not_started'
    )
    if (familiesWithOngoingOnboarding.length) {
      confirm({
        title: 'Are you sure you wanna change student campus?',
        children: <ConfirmationModalBody families={familiesWithOngoingOnboarding} />,
      }).then((confirmed) => {
        if (confirmed) {
          const formData = new FormData(e.target as HTMLFormElement)
          fetcher.submit(formData, { method: 'POST' })
        }
      })
    } else {
      const formData = new FormData(e.target as HTMLFormElement)
      fetcher.submit(formData, { method: 'POST' })
    }
  }

  const buttonDisabled = selectedFamilies.length === 0
    || formSelectState.campus.value === 0
    || formSelectState.cycle.value === 0

  return (
    <Dialog
      open
      onClose={() => {
        navigate({ pathname: '..' })
        setSelectedFamilies([])
      }}
    >
      <Dialog.Content>
        <fetcher.Form className="" onSubmit={(e) => {
          e.preventDefault()
          handleConfirmGroupChange(e)
        }}>
          <Dialog.Header>
            <Typography variant="heading-6" weight="bold">Assign a Campus</Typography>
          </Dialog.Header>
          <Dialog.Body>
            <input type="hidden" name="families" value={JSON.stringify(selectedFamilies)} />
            <input type="hidden" name="campusId" value={formSelectState.campus.value} />
            <input type="hidden" name="cycleId" value={formSelectState.cycle.value} />
            <OnboardingListbox
              options={campus}
              onChange={(value) => {
                setFormSelectState((prevState) => ({
                  ...prevState,
                  campus: value as SelectOption,
                }))
              }}
              selectedValue={formSelectState.campus}
            />
            <OnboardingListbox
              options={cycles}
              onChange={(value) => {
                setFormSelectState((prevState) => ({
                  ...prevState,
                  cycle: value as SelectOption,
                }))
              }}
              selectedValue={formSelectState.cycle}
            />
          </Dialog.Body>
          <Dialog.Footer>
            <Button onClick={() => {
              navigate('..')
              setSelectedFamilies([])
            }} variant="outlined">Cancel</Button>
            <Button disabled={buttonDisabled} type="submit">Save</Button>
          </Dialog.Footer>
        </fetcher.Form>
      </Dialog.Content>
    </Dialog>
  )
}

interface ConfirmationModalBodyProps {
  families: StudentInfo[],
}

function ConfirmationModalBody({ families }: ConfirmationModalBodyProps) {
  return (
    <div className="flex flex-col space-y-6">
      <p className="text-lg">The following students have already been invited to start the onboarding process.</p>
      <div className="flex flex-col space-y-2">
        {families.filter((family) => family.onboardingStatus).map((family) => (
          <div key={`cmb-${family.id}`} className="flex justify-between border-b border-b-gray-40 p-2">
            <p className="font-bold truncate">{family.name}</p>
            <small>{family.campus}</small>
          </div>
        ))}
      </div>
      <p className="text-lg">Changing campus will reset their onboarding.</p>
    </div>
  )
}