import React from 'react'
import { ColumnDef, SortingFn, SortingState, flexRender, getCoreRowModel, getFilteredRowModel, getSortedRowModel, useReactTable } from '@tanstack/react-table'
import { unstable_Table as Table, Icon, Button, cn } from '@design-system'
import { CellAttendance } from './cell-attendance'
import { CellGuardians } from './cell-guardians'
import { CellRequestList } from './cell-request-list'
import { CellStudentName } from './cell-student-name'
import { CellTaskSubmission } from './cell-task-submission'
import { CellActions } from './cell-actions'
import { HeaderGuardians } from './header-guardians'
import { HeaderStudent } from './header-student'
import { Student } from './types'
import { CellProgress } from './cell-progress'

interface DataTableProps {
  columnFilters: any[]
  setColumnFilters: (filters: any[]) => void
}

const sortGraduationStatusFn: SortingFn<Student> = (rowA, rowB, _columnId) => {
  const statusA = rowA.original.graduationStatus
  const statusB = rowB.original.graduationStatus
  const statusOrder = ['completed', 'ahead-of-schedule', 'on-track', 'falling-behind']
  return statusOrder.indexOf(statusA) - statusOrder.indexOf(statusB)
}

type Columns = ColumnDef<Student>[]

const columns: Columns = [
  {
    accessorKey: "studentName",
    enableSorting: true,
    header: ({ table }) => <HeaderStudent table={table} />,
    sortDescFirst: true,
    cell: ({ row }) => <CellStudentName {...row.original} />,
  },
  {
    accessorKey: "trendAttendanceCount",
    enableSorting: true,
    header: "Attendance",
    cell: ({ row }) => <CellAttendance {...row.original} />,
  },
  {
    accessorKey: "trendCompletedTasksCount",
    enableSorting: true,
    header: "Task Submission",
    cell: ({ row }) => <CellTaskSubmission {...row.original} />,
  },
  {
    accessorKey: "graduationStatus",
    enableSorting: true,
    header: "Progress",
    sortingFn: sortGraduationStatusFn,
    cell: ({ row }) => <CellProgress {...row.original} />,
  },
  {
    accessorKey: "requestListLowestCount",
    enableSorting: true,
    header: "Request List",
    filterFn: 'equals',
    invertSorting: true,
    cell: ({ row }) => <CellRequestList {...row.original} />,
  },
  {
    accessorKey: "guardians[0].name",
    header: ({ table }) => <HeaderGuardians table={table} />,
    cell: ({ row }) => <CellGuardians {...row.original} />,
  },
  {
    id: "actions",
    cell: ({ row }) => <CellActions {...row.original} />,
  },
]

export function DataTable({ data = [] }) {
  const [sorting, setSorting] = React.useState<SortingState>([])
  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    onSortingChange: setSorting,
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    state: {
      sorting,
      columnVisibility: {
        graduationStatus: data.findIndex(item => item.graduationStatus) != -1,
      }
    },
  })

  return (
    <Table>
      <Table.Header>
        {table.getHeaderGroups().map((headerGroup) => (
          <Table.Row key={headerGroup.id}>
            {headerGroup.headers.map((header) => (
              <Table.Head key={header.id}>
                <div className="flex items-center gap-1 group">
                  {header.isPlaceholder
                    ? null
                    : flexRender(
                      header.column.columnDef.header,
                      header.getContext()
                    )}
                  {header.column.columnDef.enableSorting && (
                    <Button
                      aria-label={`Sort ${header.column.columnDef.header}`}
                      variant="ghost"
                      size="xs"
                      onClick={() => header.column.toggleSorting()}
                      className={cn(!header.column.getIsSorted() && "invisible opacity-0", "group-hover:visible group-hover:opacity-100 transition-opacity")}
                    >
                      <Icon name={header.column.columnDef.invertSorting ? "arrow-top" : "arrow-bottom"} size="xs" className={cn(header.column.getIsSorted() === "asc" && "rotate-180")} />
                    </Button>
                  )}
                </div>
              </Table.Head>
            ))}
          </Table.Row>
        ))}
      </Table.Header>
      <Table.Body>
        {table.getRowModel().rows?.length ? (
          table.getRowModel().rows.map((row) => (
            <Table.Row
              key={row.id}
              data-state={row.getIsSelected() && "selected"}
            >
              {row.getVisibleCells().map((cell) => (
                <Table.Cell key={cell.id}>
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </Table.Cell>
              ))}
            </Table.Row>
          ))
        ) : (
          <Table.Row>
            <Table.Cell colSpan={table.getFlatHeaders().length} className="h-24 text-center">
              No results.
            </Table.Cell>
          </Table.Row>
        )}
      </Table.Body>
    </Table>
  )
}


