import React from 'react'
import { useNavigation, useSearchParams, Await } from 'react-router'
import { Column, flexRender, getCoreRowModel, useReactTable } from '@tanstack/react-table'
import { unstable_Table as Table, Icon, Button, cn, Card, EmptyState, unstable_Tooltip as Tooltip, Skeleton } from '@design-system'
import { TableSkeleton } from './table-skeleton'
import { ColumnFilters } from './column-filters'
import { Student } from './types'
import EmptyStateIllustration from './illustration.svg'

export function DataTable({ columns, data = [], columnVisibility, columnOrder, initialSorting, initialColumnFilters, filtersFn }) {
  const [columnFilters, setColumnFilters] = React.useState(initialColumnFilters)
  const [sorting, setSorting] = React.useState([initialSorting])
  const [searchParams, setSearchParams] = useSearchParams()

  const handleSorting = callback => {
    let newSorting = callback()
    // If sort for a third time, we reset the sorting to default
    if (sorting[0].id === newSorting[0].id && sorting[0].desc && !newSorting[0].desc) {
      newSorting = [{ id: 'name', desc: false }]
    }
    setSorting(newSorting)
    const newParams = new URLSearchParams(searchParams)
    newParams.set('sorting', JSON.stringify(newSorting[0]))
    newParams.set('page', '1')
    setSearchParams(newParams)
  }

  const handleSetColumnFilters = (newColumnFilters) => {
    setColumnFilters(newColumnFilters)
    const newParams = new URLSearchParams(searchParams)
    newParams.set('columnFilters', JSON.stringify(newColumnFilters))
    newParams.set('page', '1')
    setSearchParams(newParams)
  }

  const handleColumnFiltersChange = (callback) => {
    const newColumnFilters = callback(columnFilters)
    handleSetColumnFilters(newColumnFilters)
  }

  const handleResetFilter = (id) => () => {
    const newColumnFilters = columnFilters.filter(cf => cf.id !== id)
    handleSetColumnFilters(newColumnFilters)
  }

  const navigation = useNavigation()
  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    onSortingChange: handleSorting,
    onColumnFiltersChange: handleColumnFiltersChange,
    manualSorting: true,
    manualFiltering: true,
    state: {
      columnOrder,
      sorting,
      columnVisibility,
      columnFilters,
      columnPinning: {
        left: ['id', 'name'],
      },
    },

  })

  const getClassNameFor = (column: Column<Student>, head?: boolean) => {
    if (column.getIsPinned()) {
      if (column.id === 'id') return `sticky left-0 pr-0 max-w-16 bg-gradient-to-r from-white via-white/80 via-90% to-transparent dark:from-gray-100 dark:via-gray-100/8 md:bg-white md:dark:bg-gray-100`
      if (column.id === 'name') return `md:sticky md:left-16 md:first:left-0 md:first:pl-4 pl-0 md:bg-gradient-to-r md:from-white md:via-white/80 md:via-90% md:to-transparent md:dark:from-gray-100 md:dark:via-gray-100/80`
    }
    return ''
  }

  return (
    <>
      {columnFilters.length > 0 && (
        <React.Suspense fallback={
          <Skeleton className="w-40 h-8 rounded-full" />
        }>
          <Await
            resolve={filtersFn}
            errorElement={
              <p>Error loading!</p>
            }
          >
            {(data) => {
              const labels = columnFilters.map(({ id, value }) => {
                const items = data[id].flatMap(el => el.items ?? el)
                const title = value.map(v => items.find(el => el.id === v).title).join(', ')
                return {
                  id,
                  title
                }
              })
              return (
                <div className="flex gap-2">
                  {labels.map(({ id, title }) => (
                    <Tooltip content={title} key={id}>
                      <Button size="xs" onClick={handleResetFilter(id)}>
                        <span className='max-w-sm text-ellipsis overflow-hidden'>{title}</span>
                        <Icon name="cross" size="xs" />
                      </Button>
                    </Tooltip>
                  ))}
                </div>
              )
            }}

          </Await>
        </React.Suspense>
      )}
      <Card>
        <Table>
          <Table.Header className="bg-white dark:bg-gray-100">
            {table.getHeaderGroups().map((headerGroup) => (
              <Table.Row key={headerGroup.id}>
                {headerGroup.headers.map((header) => (
                  <Table.Head key={header.id} className={cn(getClassNameFor(header.column, true))} style={{ minWidth: header.column.getSize() }}>
                    <div className="flex items-center gap-1 group/table">
                      {header.isPlaceholder
                        ? null
                        : flexRender(
                          header.column.columnDef.header,
                          header.getContext()
                        )}
                      {header.column.getCanFilter() ? (
                        <ColumnFilters column={header.column} filtersFn={filtersFn} table={table} />
                      ) : null}
                      {header.column.columnDef.enableSorting && (
                        <Tooltip content='Sort'>
                          <Button
                            aria-label={`Sort ${header.column.columnDef.header}`}
                            variant="ghost"
                            size="xs"
                            onClick={() => header.column.toggleSorting()}
                            className={cn(!header.column.getIsSorted() && "hidden", "group-hover/table:flex")}
                          >
                            <Icon name={header.column.columnDef.invertSorting ? "arrow-bottom" : "arrow-top"} size="xs" className={cn(header.column.getIsSorted() === "asc" && "rotate-180")} />
                          </Button>
                        </Tooltip>
                      )}
                    </div>
                  </Table.Head>
                ))}
              </Table.Row>
            ))}
          </Table.Header>
          {navigation.state === 'loading' ? (
            <TableSkeleton rows={20} columnsLength={columns.length} />
          ) : (
            <Table.Body>
              {table.getRowModel().rows?.length ? (
                table.getRowModel().rows.map((row) => (
                  <Table.Row
                    key={row.id}
                    data-state={row.getIsSelected() && "selected"}
                  >
                    {row.getVisibleCells().map((cell) => (
                      <Table.Cell key={cell.id} className={cn(getClassNameFor(cell.column))}>
                        {flexRender(cell.column.columnDef.cell, cell.getContext())}
                      </Table.Cell>
                    ))}
                  </Table.Row>
                ))
              ) : (
                <Table.Row>
                  <Table.Cell colSpan={table.getFlatHeaders().length}>
                    <div className="sticky left-4 max-w-2xl w-full">
                      <EmptyState>
                        <EmptyState.Illustration>
                          <img src={EmptyStateIllustration} alt="No results found" />
                        </EmptyState.Illustration>
                        <EmptyState.Title>No results found</EmptyState.Title>
                        <EmptyState.Description>Check your filters or try adjusting your search</EmptyState.Description>
                      </EmptyState>
                    </div>
                  </Table.Cell>
                </Table.Row>
              )}
            </Table.Body>
          )}
        </Table>
      </Card>
    </>
  )
}


