import { faCircleXmark, faTriangleExclamation } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { forwardRef, ElementType } from 'react'
import type { PolymorphicPropsWithoutRef, PolymorphicForwardRefExoticComponent } from 'react-polymorphic-types'
import { Adornment } from './adornment'
import { useLabelFor, useDescribedBy } from '../../hooks/useAccessibility'
import { cn, Typography } from '@design-system'

const DEFAULT_ELEMENT = 'input'

interface TextFieldOwnProps<T extends ElementType> {
  /**
   * An override of the default HTML tag. All tag parameters are available like value, onChange, disabled and etc.
   * @type 'input' | 'textarea' | 'TextareaAutosize' | 'select' | 'date'
   * @default input
   */
  type?: string

  as?: T
  /**
   * The label content.
   */
  label?: string
  /**
   * Text to give context about a field’s input, such as how the input will be used.
   */
  helperText?: string
  /**
   * If a string is passed, the input is displayed in an error state with the error message instead of the helperText
   */
  error?: string | boolean
  /**
   * If true, the input will take up the full width of its container.
   */
  fullWidth?: boolean
  /**
   * Start `TextField.Adornment` for this component.
   * @type TextField.Adornment
   */
  startAdornment?: JSX.Element
  /**
   * End `TextField.Adornment` for this component.
   * @type TextField.Adornment
   */
  endAdornment?: JSX.Element
  /**
   * If true, the component is disabled.
   */
  disabled?: boolean
  /**
   * If true, the component is readOnly.
   */
  readOnly?: boolean
  /**
   * If true, the component is required and asterisk is add to the helperText or Label.
   */
  required?: boolean

  /**
   * If true, the component is will trim the trailing and leading white spaces on blur.
   */
  trim?: boolean
}

export type TextFieldProps<T extends ElementType = typeof DEFAULT_ELEMENT> = PolymorphicPropsWithoutRef<
  TextFieldOwnProps<T>,
  T
>

const LIGHT_CLASSES =
  'bg-white text-gray-90 border-2 border-gray-30' +
  ' hover:bg-gray-10 hover:border-gray-50 hover:text-gray-100' +
  ' focus-within:ring-1 focus-within:ring-accent focus-within:!bg-white focus-within:text-gray-100 focus-within:border-gray-50)'

const DARK_CLASSES =
  'dark:bg-gray-90 dark:text-gray-5 dark:border-gray-60' +
  ' dark:hover:bg-gray-95 dark:hover:border-gray-50 dark:hover:text-gray-5' +
  ' dark:focus-within:ring-1 dark:focus-within:!bg-gray-100 dark:focus-within:border-gray-50 dark:focus-within:text-gray-5'

export const disabledClasses =
  '!bg-gray-5 !border-gray-15 !text-gray-60 dark:!bg-alpha/20 dark:!border-alpha/10 dark:!text-alpha/30'

const getHelperTextValue = (helperText) => {
  if (typeof helperText === 'boolean') {
    return <>&nbsp;</>
  }
  return helperText
}

const Required = () => (
  <span data-testid="required" className="text-danger-40 font-bold">
    {' '}
    *
  </span>
)

/**
 * TextField allow users to enter text into a UI. They typically appear in forms and dialogs.
 */
const TextFieldRoot: PolymorphicForwardRefExoticComponent<
  TextFieldOwnProps<ElementType>,
  typeof DEFAULT_ELEMENT
> = forwardRef(
  <E extends ElementType = typeof DEFAULT_ELEMENT>(
    {
      as,
      startAdornment,
      endAdornment,
      error,
      label,
      helperText,
      fullWidth,
      disabled,
      readOnly,
      required,
      type,
      trim,
      className,
      ...rest
    }: TextFieldProps<E>,
    ref: React.ForwardedRef<Element>
  ): JSX.Element => {
    const Input: ElementType = as
    const [labelID, labelFor] = useLabelFor()
    const [descriptionID, describedBy] = useDescribedBy()
    const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent)
    return (
      <div data-testid="main" className={cn('space-y-1', fullWidth && 'w-full', className)}>
        {label && (
          <Typography asChild variant="callout">
            <label data-testid="label" {...labelFor}>
              {label}
              {required && (!helperText || (error && typeof error !== 'string')) && <Required />}
            </label>
          </Typography>
        )}
        <div
          data-testid="inputWrapper"
          className={`textfield px-2 flex gap-1 items-center transition duration-300 rounded-lg ${LIGHT_CLASSES} ${DARK_CLASSES} ${disabled || readOnly ? disabledClasses : ``
            } ${Boolean(error) ? `!border-danger-40 dark:!border-danger-30` : ``} ${as === 'input' ? 'h-12' : `min-h-12 py-2`
            } `}
        >
          <>
            {startAdornment}
            <Input
              data-testid="input"
              {...rest}
              id={labelID}
              ref={ref}
              disabled={disabled}
              readOnly={readOnly}
              required={required}
              defaultValue={rest.defaultValue || ''}
              type={type === 'date' && !rest.defaultValue ? 'text' : type}
              {...describedBy}
              className={`w-full h-full p-0 px-1 outline-none border-0 bg-transparent !ring-0 text-current tracking-tight placeholder-gray-80 dark:placeholder-gray-60 dark:focus-within:!bg-gray-100 dark:(children:bg-gray-80 children:text-white) ${readOnly && as === 'select' ? 'pointer-events-none' : ''
                }`}
              onMouseOver={(e) => type === 'date' && (e.target.type = 'date')}
              onMouseOut={(e) => {
                const target = e.target
                const isNotActive = document.activeElement !== target
                type === 'date' && !target.value && isNotActive && (target.type = 'text')
              }}
              onFocus={(e) => type === 'date' && (e.target.type = 'date')}
              onBlur={(e) => {
                const value = e.target.value
                type === 'date' && !value && (e.target.type = 'text')
                if (trim) e.target.value = value.trim()
              }}
            />
            {endAdornment}
            {isSafari && type === 'date' && (
              <button
                type="button"
                className="opacity-50 hover:opacity-100"
                onClick={(e) => {
                  const input = e.target.closest('div').getElementsByTagName('input')[0]
                  input.value = null
                  input.type = 'text'
                }}
              >
                <FontAwesomeIcon data-testid="clear" icon={faCircleXmark} />
              </button>
            )}
          </>
        </div>
        {typeof error === 'string' && (
          <p id="error" data-testid="error" className="text-xs text-danger-40 dark:text-danger-30 space-x-1 flex">
            <FontAwesomeIcon data-testid="alertIcon" icon={faTriangleExclamation} />
            <span className="whitespace-pre-line">
              {error}
              {required && <Required />}
            </span>
          </p>
        )}
        {typeof error !== 'string' && helperText && (
          <p data-testid="helperText" id={descriptionID} className="text-xs text-gray-90 dark:text-white">
            {getHelperTextValue(helperText)}
            {required && <Required />}
          </p>
        )}
      </div>
    )
  }
)

TextFieldRoot.defaultProps = {
  as: DEFAULT_ELEMENT,
  type: 'text',
  fullWidth: false,
}

TextFieldRoot.displayName = 'TextField'

export const TextField = Object.assign(TextFieldRoot, { Adornment })
