import * as React from 'react'
import { FormControl, FormField } from '@radix-ui/react-form'
import { LabelValue } from './types'
import { SoraFormLabel } from './primitives'
import { unstable_Tooltip as Tooltip, unstable_RadioGroup as RadioGroup } from '@design-system'

interface TaskTypeRadioGroupProps {
  defaultValue: string
  name: string
  taskTypes: (LabelValue & { tooltipContent: string })[]
}
export function TaskTypeRadioGroup({ defaultValue, name, taskTypes }: TaskTypeRadioGroupProps) {
  return (
    <FormField className="w-full gap-1 flex flex-col" name={name}>
      <SoraFormLabel>Task type</SoraFormLabel>
      <FormControl asChild>
        <RadioGroup defaultValue={defaultValue ?? 'regular'}>
          {taskTypes.map(({ value, label, tooltipContent }) => (
            <Tooltip key={value} content={tooltipContent}>
              <RadioGroup.Item value={value} label={label} />
            </Tooltip>
          ))}
        </RadioGroup>
      </FormControl>
    </FormField>
  )
}