import React, { useState } from 'react'
import axios from 'axios'
import { Form, useLoaderData, useSubmit } from 'react-router'
import SoraLink from '@components/link'

import {
  Column,
  Table,
  createColumnHelper,
  useReactTable,
  ColumnFiltersState,
  getCoreRowModel,
  getFilteredRowModel,
  getFacetedRowModel,
  getFacetedUniqueValues,
  getFacetedMinMaxValues,
  getPaginationRowModel,
  getSortedRowModel,
  flexRender,
  PaginationState,
} from '@tanstack/react-table'
import { Button } from '@designsystem'
import Select from '@components/forms/select'

export const loader = async ({ request }) => {
  const searchParams = new URL(request.url).searchParams

  const downloadAttachment = (url: string, filename: string) => {
    fetch(url)
      .then(response => response.blob())
      .then(blob => {
        const link = document.createElement('a')
        link.href = URL.createObjectURL(blob)
        link.download = filename
        link.click()
      })
      .catch(console.error)
  }

  if (searchParams.get('output') === 'csv') {
    downloadAttachment(
      `/api/pages/admin/reports/units-abilities-completion?${searchParams.toString()}`,
      `units_completion_report_${(new Date()).toISOString().split('T')[0]}.csv`
    )
    return {}
  }

  const result = await axios.get('/pages/admin/reports/units-abilities-completion', { params: searchParams })
  return result?.data
}

type UnitsAbilitiesCompletionRow = {
  type: string
  school_stage: string
  topic: string
  title: string
  total_students: number
  total_unregistered_students: number
  total_registered_students: number
}

function Filter({
  column,
  table,
}: {
  column: Column<any, unknown>
  table: Table<any>
}) {
  const firstValue = table
    .getPreFilteredRowModel()
    .flatRows[0]?.getValue(column.id)

  const columnFilterValue = column.getFilterValue()

  const sortedUniqueValues = React.useMemo(
    () => Array.from(column.getFacetedUniqueValues().keys()).sort(),
    [column.getFacetedUniqueValues()]
  )

  return typeof firstValue === 'number' ? (
    <div>
      <datalist id={column.id + 'list'}>
        {sortedUniqueValues.slice(0, 5000).map((value: any) => (
          <option value={value} key={value} />
        ))}
      </datalist>
      <div className="flex space-x-2">
        <DebouncedInput
          type="text"
          value={(columnFilterValue as [number, number])?.[0] ?? ''}
          onChange={value =>
            column.setFilterValue([value, value])
          }
          placeholder={`Search... (${column.getFacetedUniqueValues().size})`}
          className="w-full min-w-25 border shadow rounded"
          list={column.id + 'list'}
          debounce={1}
        />
      </div>
      <div className="h-1" />
    </div>
  ) : (
    <>
      <datalist id={column.id + 'list'}>
        {sortedUniqueValues.slice(0, 5000).map((value: any) => (
          <option value={value} key={value} />
        ))}
      </datalist>
      <DebouncedInput
        type="text"
        value={(columnFilterValue ?? '') as string}
        onChange={value => column.setFilterValue(value)}
        placeholder={`Search... (${column.getFacetedUniqueValues().size})`}
        className="w-full border shadow rounded"
        list={column.id + 'list'}
      />
      <div className="h-1" />
    </>
  )
}

function DebouncedInput({
  value: initialValue,
  onChange,
  debounce = 500,
  ...props
}: {
  value: string | number
  onChange: (value: string | number) => void
  debounce?: number
} & Omit<React.InputHTMLAttributes<HTMLInputElement>, 'onChange'>) {
  const [value, setValue] = React.useState(initialValue)

  React.useEffect(() => {
    setValue(initialValue)
  }, [initialValue])

  React.useEffect(() => {
    const timeout = setTimeout(() => {
      onChange(value)
    }, debounce)

    return () => clearTimeout(timeout)
  }, [value])

  return (
    <input {...props} value={value} onChange={e => setValue(e.target.value)} />
  )
}

export default function UnitsAbilitiesCompletion() {
  const { data = [], filterOptions = {
    schoolStageFilterOptions: [],
    campusFilterOptions: [],
    expectedGraduationOptions: [],
    gradeOptions: [],
  } }: any = useLoaderData()
  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([])
  const [pagination, setPagination] = useState<PaginationState>({ pageIndex: 0, pageSize: 25 })

  const columnHelper = createColumnHelper<UnitsAbilitiesCompletionRow>()

  const columns = [
    columnHelper.accessor('type', {
      header: 'Type',
      cell: info => info.getValue(),
    }),
    columnHelper.accessor('school_stage', {
      header: 'School Stage',
      cell: info => info.getValue(),
    }),
    columnHelper.accessor('topic', {
      header: 'Topic',
      cell: info => info.getValue(),
    }),
    columnHelper.accessor('title', {
      header: 'Unit/Ability',
      cell: info => info.getValue(),
    }),
    columnHelper.accessor('total_students', {
      header: '# Incomplete',
      cell: info => info.getValue(),
    }),
    columnHelper.accessor('total_unregistered_students', {
      header: '# Incomplete and Unregistered',
      cell: info => info.getValue(),
    }), columnHelper.accessor('total_registered_students', {
      header: '# Incomplete and Registered',
      cell: info => info.getValue(),
    }),
  ]

  const table = useReactTable({
    data,
    columns,
    state: {
      columnFilters,
      pagination,
    },
    onPaginationChange: setPagination,
    onColumnFiltersChange: setColumnFilters,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getFacetedRowModel: getFacetedRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
    getFacetedMinMaxValues: getFacetedMinMaxValues(),
  })

  return (
    <div className='flex flex-col'>
      <Form id="filter-form" method="GET" className="border rounded p-2">
        <div className="pb-4">
          <h6 className="font-bold">Student Filters</h6>
          <h6 className="text-sm mt-1">Refine your report by selecting specific student filters. The report will compile data exclusively for students who meet all the chosen criteria.</h6>
        </div>
        <div className='grid grid-cols-2 gap-1'>
          <div className="flex flex-col border border-gray-20 p-2 rounded-xl">
            <h3 className="font-bold text-md mb-2 mr-2">Type</h3>
            <Select
              options={filterOptions.typeFilterOptions}
              defaultValue={filterOptions.typeFilterOptions?.filter(o => o.selected)}
              isMulti
              name="type"
              placeholder="Select the type"
            />
          </div>
          <div className="flex flex-col border border-gray-20 p-2 rounded-xl">
            <h3 className="font-bold text-md mb-2 mr-2">School Stage</h3>
            <Select
              options={filterOptions.schoolStageFilterOptions}
              defaultValue={filterOptions.schoolStageFilterOptions?.filter(o => o.selected)}
              isMulti
              name="school_stage"
              placeholder="Select the school stage"
            />
          </div>
          <div className="flex flex-col border border-gray-20 p-2 rounded-xl">
            <span className="font-bold text-md mb-2 inline-block mr-2">Campus</span>
            <Select
              options={filterOptions.campusFilterOptions}
              defaultValue={filterOptions.campusFilterOptions?.filter(o => o.selected)}
              isMulti
              name="campus"
              placeholder="Select the campus"
              className="inline-block grow"
            />
          </div>
          <div className="flex flex-col border border-gray-20 p-2 rounded-xl">
            <span className="font-bold text-md mb-2 inline-block mr-2">Expected Graduation</span>
            <Select
              options={filterOptions.expectedGraduationOptions}
              defaultValue={filterOptions.expectedGraduationOptions?.filter(o => o.selected)}
              isMulti
              name="expected_graduation"
              placeholder="Select the expected graduation"
              className="inline-block grow"
            />
          </div>
          <div className="flex flex-col border p-2 rounded-xl">
            <span className="font-bold text-md mb-2 inline-block mr-2">Grade</span>
            <Select
              options={filterOptions.gradeOptions}
              defaultValue={filterOptions.gradeOptions?.filter(o => o.selected)}
              isMulti
              name="grade"
              placeholder="Select the grade"
              className="inline-block grow"
            />
          </div>
          <div className="col-span-2 flex justify-end gap-2 my-2">
            <Button name="output" value="csv" type='submit'>Download as CSV</Button>
            <Button type='submit'>Filter</Button>
          </div>
        </div>
      </Form>
      {!data.length && (
        <div className="mt-5 text-center bg-blue-2 p-2 border border-blue-5 rounded">
          No results found. Adjust your filters and try again to display data.
        </div>
      )}
      {!!data.length && (
        <div className='overflow-x-auto p-2'>
          <table className='w-full'>
            <thead>
              {table.getHeaderGroups().map(headerGroup => (
                <tr key={headerGroup.id} className='text-left '>
                  {headerGroup.headers.map(header => {
                    return (
                      <th key={header.id} colSpan={header.colSpan} className='px-3'>
                        {header.isPlaceholder ? null : (
                          <>
                            <div
                              {...{
                                className: header.column.getCanSort()
                                  ? 'cursor-pointer select-none'
                                  : '',
                                onClick: header.column.getToggleSortingHandler(),
                              }}
                            >
                              {flexRender(
                                header.column.columnDef.header,
                                header.getContext()
                              )}
                              {{
                                asc: ' 🔼',
                                desc: ' 🔽',
                              }[header.column.getIsSorted() as string] ?? null}
                            </div>
                            {header.column.getCanFilter() ? (
                              <div>
                                <Filter column={header.column} table={table} />
                              </div>
                            ) : null}
                          </>
                        )}
                      </th>
                    )
                  })}
                </tr>
              ))}
            </thead>
            <tbody>
              {table.getRowModel().rows.map(row => {
                return (
                  <tr key={row.id}>
                    {row.getVisibleCells().map(cell => {
                      return (
                        <td key={cell.id} className='px-3'>
                          {flexRender(
                            cell.column.columnDef.cell,
                            cell.getContext()
                          )}
                        </td>
                      )
                    })}
                  </tr>
                )
              })}
            </tbody>
          </table>
          <div className="flex flex-row items-center justify-end py-2 gap-3">
            <button
              className="border rounded p-1"
              onClick={() => table.setPageIndex(0)}
              disabled={!table.getCanPreviousPage()}
            >
              {'<<'}
            </button>
            <button
              className="border rounded p-1"
              onClick={() => table.previousPage()}
              disabled={!table.getCanPreviousPage()}
            >
              {'<'}
            </button>
            <button
              className="border rounded p-1"
              onClick={() => table.nextPage()}
              disabled={!table.getCanNextPage()}
            >
              {'>'}
            </button>
            <button
              className="border rounded p-1"
              onClick={() => table.setPageIndex(table.getPageCount() - 1)}
              disabled={!table.getCanNextPage()}
            >
              {'>>'}
            </button>
            <span className="flex items-center gap-1">
              <div>Page</div>
              <strong>
                {table.getState().pagination.pageIndex + 1} of{' '}
                {table.getPageCount()}
              </strong>
            </span>
            <span className="flex items-center gap-1">
              | Go to page:
              <input
                type="number"
                defaultValue={table.getState().pagination.pageIndex + 1}
                onChange={e => {
                  const page = e.target.value ? Number(e.target.value) - 1 : 0
                  table.setPageIndex(page)
                }}
                className="border p-1 rounded w-16"
              />
            </span>
            <select
              value={table.getState().pagination.pageSize}
              onChange={e => {
                table.setPageSize(Number(e.target.value))
              }}
            >
              {[25, 50, 100, data.length].map(pageSize => (
                <option key={pageSize} value={pageSize}>
                  Show {pageSize === data.length ? 'All' : pageSize}
                </option>
              ))}
            </select>
          </div>
        </div>
      )}
    </div>
  )
}

