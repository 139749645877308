import * as React from 'react'
import { Button, Icon, Typography } from '@design-system'

interface FileUploaderProps extends React.PropsWithChildren {
  name: HTMLInputElement['name']
  label: string
  accept?: HTMLInputElement['accept']
  required?: HTMLInputElement['required']
  defaultValue?: HTMLInputElement['defaultValue']
  onChangeCallback?: () => void
  error?: string
  readOnly: boolean
  urlFieldName?: string
}

function Root({ name, urlFieldName, label, accept = "image/*", required = false, defaultValue, onChangeCallback, error, readOnly, children }: FileUploaderProps) {
  const [preview, setPreview] = React.useState<string | null>(defaultValue || null)
  const fileInputRef = React.useRef<HTMLInputElement>(null)

  React.useEffect(() => {
    if (defaultValue) {
      setPreview(defaultValue)
    }
  }, [defaultValue])

  const handleFileChange = (file: File) => {
    if (file) {
      const reader = new FileReader()
      reader.onloadend = () => {
        setPreview(reader.result as string)
      }
      reader.readAsDataURL(file)
      if (onChangeCallback) onChangeCallback()
    }
  }

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0]
    if (file) handleFileChange(file)
  }

  const handleRemove = () => {
    if (onChangeCallback) onChangeCallback()
    setPreview(null)
    if (fileInputRef.current) {
      fileInputRef.current.value = ''
    }
  }

  return (
    <div className="space-y-1">
      <Typography asChild variant="callout" weight="medium">
        <label htmlFor={name}>
          {label}
          {required && <span className="text-danger font-bold">{' *'}</span>}
        </label>
      </Typography>
      <div className="relative w-full h-56 flex flex-col justify-center items-center cursor-pointer rounded-md border">
        {urlFieldName && <input type="hidden" name={urlFieldName} value={defaultValue} />}
        <input
          type="file"
          id={name}
          name={name}
          accept={accept}
          onChange={handleInputChange}
          ref={fileInputRef}
          className="sr-only"
        />
        {preview ? (
          <FilePreview
            preview={preview}
            fileName={fileInputRef.current?.files?.[0]?.name}
            defaultValue={defaultValue}
            readOnly={readOnly}
            handleRemove={handleRemove}
          />
        ) : (
          <label htmlFor={name} className="space-y-1 place-items-center">
            {children}
          </label>
        )
        }
      </div >
      {error && (
        <Typography variant="footnote" color="danger" className="flex items-center gap-1">
          <Icon size="2xs" name="alert-triangle-filled" />
          <span>
            {error}
          </span>
        </Typography>
      )}
    </div >
  )
}

Root.displayName = 'FileUploader'

function FilePreview({ preview, fileName, defaultValue, readOnly, handleRemove }) {
  const imageExtensions = ['jpg', 'jpeg', 'png', 'heic', 'webp', 'gif', 'bmp']
  const currentFileExtension = (fileName ?? defaultValue).split('.').pop().toLowerCase()
  const isImage = imageExtensions.includes(currentFileExtension)
  if (isImage) {
    return (
      <>
        <img src={preview} alt="Preview" className="w-full h-56 object-cover rounded-md" />
        {!readOnly && (
          <Button
            variant="contained"
            size="sm"
            onClick={handleRemove}
            className="absolute top-2 right-2"
            aria-label="Remove image"
          >
            <Icon name="trash-2" size="sm" />
          </Button>
        )}
      </>
    )
  }

  return (
    <div className="space-y-1 place-items-center">
      <Icon name="file-text" size="lg" />
      <Typography variant="body">
        {fileName}
      </Typography>
      {!readOnly && (
        <Button
          size="sm"
          onClick={handleRemove}
          className="absolute top-2 right-2"
          aria-label="Remove image"
        >
          <Icon name="trash-2" size="sm" />
        </Button>
      )}
    </div>
  )
}

function Title({ children }: { children: React.ReactNode }) {
  return <Typography weight="bold" variant="body">{children}</Typography>
}

Title.displayName = 'FileUploader.Title'

function Description({ children }: { children: React.ReactNode }) {
  return <Typography variant="callout">{children}</Typography>
}

Description.displayName = 'FileUploader.Description'

function Actions({ children }: { children: React.ReactNode }) {
  return <div className="flex gap-2 items-center">{children}</div>
}

Actions.displayName = 'FileUploader.Actions'

export const FileUploader = Object.assign(Root, { Title, Description, Actions })
