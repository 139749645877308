
import * as React from 'react'
import axios from 'axios'
import { format } from 'date-fns'
import { LoaderFunctionArgs, useLoaderData, ActionFunctionArgs, useBlocker, useParams } from 'react-router'
import SoraLink from '@components/link'
import { TextField } from '@designsystem'
import useConfirmModal from '@hooks/useConfirmModal'
import SlateTextarea from '@components/forms/slate-textarea'
import { Button, Icon, useFetcher, unstable_Tooltip as Tooltip, Typography, Combobox, FileUploader } from '@design-system'

type LabelValue = {
  label: string,
  value: string,
}

type LoaderData = {
  title: string,
  isReadonlyForm: boolean,
  schoolStages: LabelValue[],
  clubSponsors: LabelValue[],
  clubTimeBlocks: LabelValue[],
  studentsBySchoolStage: Record<string, LabelValue[]>,
  defaultData?: {
    title: string,
    description: any,
    image_url: string,
    image_alt: string,
    max_students: number,
    sponsor_employee_id: string,
    school_stage: string,
    block_id: string,
    president_student_id: number,
    vice_president_student_id: number,
  }
}

async function loader({ params }: LoaderFunctionArgs) {
  const { data } = await axios.get(`/backoffice/clubs/${params.clubId ? `${params.clubId}/` : ''}edit`)
  return data
}

async function action({ request, params }: ActionFunctionArgs) {
  const { data } = await axios.post(`/backoffice/clubs/${params.clubId ? `${params.clubId}/` : ''}edit`, await request.formData(), {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  }).catch(error => {
    return {
      data: {
        toast: {
          appearance: 'error',
          message: error.response.data.error.message
        },
        errors: error.response.data.errors,
      }
    }
  })
  return data
}

function Element() {
  const params = useParams()
  const fetcher = useFetcher()
  const confirm = useConfirmModal()
  const isFormDirty = React.useRef(false)
  const loaderData = useLoaderData() as LoaderData
  const [clubTitle, setClubTitle] = React.useState(loaderData.defaultData?.title ?? '')
  const [buttonVariant, setButtonVariant] = React.useState((isFormDirty.current || !params.clubId) && !loaderData.isReadonlyForm ? 'button' : 'link')
  const [selectedSchoolStage, setSelectedSchoolStage] = React.useState(loaderData.defaultData?.school_stage ?? loaderData.schoolStages[0].value)
  const primaryButtonTitle = loaderData.isReadonlyForm ? 'Next: View members' : 'Next: Add members'

  const blocker = useBlocker(
    ({ currentLocation, nextLocation }) => {
      return !/\/clubs\/\d{1,}\/members/g.test(nextLocation.pathname)
        && currentLocation.pathname !== nextLocation.pathname
        && isFormDirty.current
        && !fetcher.data?.redirect
    }
  )

  const setFormDirty = () => {
    isFormDirty.current = true
    if (!loaderData.isReadonlyForm) setButtonVariant('button')
  }

  const handleChangeSchoolStage = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const newValue = e.target.value
    setFormDirty()
    setSelectedSchoolStage(newValue)
    setClubTitle(`[${newValue.toUpperCase()}] ${clubTitle.replace(/^\[\w+\]\s+/, '')}`)
  }

  const handleChangeClubTitle = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value
    setFormDirty()
    if (newValue.length === 0) setClubTitle('')
    else {
      const withStrippedPrefix = newValue.replace(/^\[\w+\]\s+/, '')
      setClubTitle(`[${selectedSchoolStage.toUpperCase()}] ${withStrippedPrefix}`)
    }
  }

  React.useEffect(() => {
    if (blocker.state === 'blocked') {
      confirm({
        title: 'Are you sure you want to leave this page?',
        subtitle: 'You have unsaved changes. If you leave, your changes will be lost.',
        confirmLabel: 'Leave',
        cancelLabel: 'Cancel',
      }).then((confirm) => {
        if (confirm) {
          blocker.proceed()
        } else {
          blocker.reset()
        }
      })
    }
  }, [blocker.state])

  return (
    <div className="mb-16 overflow-auto">
      <div className="flex gap-2 mb-8">
        <Tooltip content="Back">
          <Button asChild variant="outlined" color="soft" size="sm" aria-label="Back">
            <SoraLink to="/clubs">
              <Icon name="arrow-left" size="sm" />
            </SoraLink>
          </Button>
        </Tooltip>
        <Typography variant="heading-3" weight="bold">{loaderData.title}</Typography>
      </div>
      <fetcher.Form method="POST" className="flex flex-col gap-6" id="club-form" noValidate encType="multipart/form-data" >
        <Combobox
          required
          disabled={loaderData.isReadonlyForm}
          name="school_stage"
          label="School stage"
          placeholder="Select a school stage"
          defaultValue={selectedSchoolStage}
          onChange={handleChangeSchoolStage}
          error={fetcher.data?.formErrors?.school_stage}
        >
          {loaderData.schoolStages.map(({ label, value }) => (
            <Combobox.Item key={value} value={value}>{label}</Combobox.Item>
          ))}
        </Combobox>
        <TextField name="title" label="Title" required value={clubTitle} error={fetcher.data?.formErrors?.title} onChange={handleChangeClubTitle} readOnly={loaderData.isReadonlyForm} />
        <div className="space-y-1">
          <Typography asChild variant="callout" weight="medium">
            <label htmlFor="description">
              Description
            </label>
          </Typography>
          <SlateTextarea
            /* @ts-ignore: Unreachable code error */
            readOnly={loaderData.isReadonlyForm}
            noAttachments
            onChange={setFormDirty}
            value={loaderData.defaultData?.description}
            id="description"
            name="description"
            placeholder="Type club description here"
            className="h-48"
          />
        </div>
        <Combobox
          required
          label="Club sponsor"
          onChange={setFormDirty}
          name="sponsor_employee_id"
          placeholder="Select a club sponsor"
          disabled={loaderData.isReadonlyForm}
          error={fetcher.data?.formErrors?.sponsor_employee_id}
          search={<Combobox.Search placeholder="Search club sponsors" />}
          defaultValue={loaderData.defaultData?.sponsor_employee_id ?? ''}
        >
          {loaderData.clubSponsors.map(({ label, value }) => (
            <Combobox.Item key={value} value={value}>
              {label}
            </Combobox.Item>
          ))}
        </Combobox>
        <div className="grid gap-6 grid-cols-2">
          <Combobox
            required
            name="block_id"
            label="Meeting time"
            onChange={setFormDirty}
            disabled={loaderData.isReadonlyForm}
            placeholder="Select a meeting time"
            defaultValue={loaderData.defaultData?.block_id ?? ''}
          >
            {loaderData.clubTimeBlocks.map(({ label, value }) => (
              <Combobox.Item key={value} value={value}>
                {format(new Date(label.split(' - ')[0].trim()), 'h:mm a')} - {label.split(' - ')[1]}
              </Combobox.Item>
            ))}
          </Combobox>
          <TextField
            min="0"
            required
            type="number"
            name="max_students"
            onChange={setFormDirty}
            label="Max number of students"
            readOnly={loaderData.isReadonlyForm}
            error={fetcher.data?.formErrors?.max_students}
            defaultValue={loaderData.defaultData?.max_students ?? 0}
          />
          <Combobox
            key={`president_${selectedSchoolStage}`}
            label="President"
            onChange={setFormDirty}
            name="president_student_id"
            disabled={loaderData.isReadonlyForm}
            placeholder="Select the club president"
            search={<Combobox.Search placeholder="Search..." />}
            error={fetcher.data?.formErrors?.president_student_id}
            defaultValue={String(loaderData.defaultData?.president_student_id)}
          >
            {loaderData.studentsBySchoolStage[selectedSchoolStage].map(({ label, value }) => (
              <Combobox.Item key={`p-${value}`} value={String(value)}>{label}</Combobox.Item>
            ))}
          </Combobox>
          <Combobox
            key={`vice_president_${selectedSchoolStage}`}
            label="Vice President"
            onChange={setFormDirty}
            name="vice_president_student_id"
            disabled={loaderData.isReadonlyForm}
            placeholder="Select the club vice president"
            search={<Combobox.Search placeholder="Search..." />}
            error={fetcher.data?.formErrors?.vice_president_student_id}
            defaultValue={String(loaderData.defaultData?.vice_president_student_id)}
          >
            {loaderData.studentsBySchoolStage[selectedSchoolStage].map(({ label, value }) => (
              <Combobox.Item key={`vp-${value}`} value={String(value)}>{label}</Combobox.Item>
            ))}
          </Combobox>
        </div>
        <FileUploader
          required
          name="image_file"
          label="Club image"
          onChangeCallback={setFormDirty}
          readOnly={loaderData.isReadonlyForm}
          error={fetcher.data?.formErrors?.image_url}
          defaultValue={loaderData.defaultData?.image_url ?? ''}
          urlFieldName="image_url"
        >
          <FileUploader.Title>Upload image</FileUploader.Title>
          <FileUploader.Description>Upload any image file type. File size no more than 20MB.</FileUploader.Description>
          <FileUploader.Actions>
            {!loaderData.isReadonlyForm && <Button variant="outlined" size="sm" asChild><p>Upload file</p></Button>}
          </FileUploader.Actions>
        </FileUploader>
        <TextField
          required
          name="image_alt"
          onChange={setFormDirty}
          label="Image description"
          readOnly={loaderData.isReadonlyForm}
          error={fetcher.data?.formErrors?.image_alt}
          defaultValue={loaderData.defaultData?.image_alt ?? ''}
        />
      </fetcher.Form>
      <div className="bg-white dark:bg-gray-90 border-t border-t-1 items-center border-gray-40 px-40 h-16 w-full flex flex-row justify-end fixed bottom-0 left-0 right-0 z-10">
        <div className="space-x-2">
          <Button
            asChild
            size="sm"
            variant="outlined"
          >
            <SoraLink to="../clubs">
              Cancel
            </SoraLink>
          </Button>
          {buttonVariant === 'button' ? (
            <Button
              size="sm"
              type="submit"
              form="club-form"
              loading={fetcher.state === 'submitting'}
            >
              {primaryButtonTitle}
            </Button>
          ) : (
            <Button
              asChild
              size="sm"
            >
              <SoraLink to={`../clubs/${params.clubId}/members`}>
                {primaryButtonTitle}
              </SoraLink>
            </Button>
          )}
        </div>
      </div>
    </div>
  )
}

export const ClubsEditRoute = {
  action,
  loader,
  Element,
}

