import React from 'react'
import { Typography, unstable_Tooltip as Tooltip } from '@design-system'
import { Student } from './types'

interface CellMathProps extends Pick<Student, 'mathPlacement'> { }

export const CellMath = ({ mathPlacement }: CellMathProps) => {
  return (
    <>
      {mathPlacement}
    </>
  )
}
