import React, { Fragment } from 'react'
import { Await } from 'react-router'
import { Button, DropdownMenu, Command, cn, unstable_Tooltip as Tooltip, Icon, Spinner } from '@design-system'

export const ColumnFilters = ({ filtersFn, column, table }) => {
  const [open, setOpen] = React.useState(false)
  const isMultiple = column.columnDef.meta.filterIsMultiple
  const handleChange = (id) => () => {
    const prevFilterValue = column.getFilterValue() || []
    let newFilterValue
    if (isMultiple) {
      newFilterValue = prevFilterValue.includes(id)
        ? prevFilterValue.filter((itemId) => itemId !== id)
        : [...prevFilterValue, id]
      if (newFilterValue.length === 0) newFilterValue = undefined
    } else {
      newFilterValue = prevFilterValue.includes(id) ? undefined : [id]
      setOpen(false)
    }
    column.setFilterValue(newFilterValue)
  }
  return (
    <DropdownMenu open={open} onOpenChange={setOpen} className="group/dropdown">
      <Tooltip content='Filter'>
        <DropdownMenu.Trigger asChild>
          <div>
            <Button variant="ghost" size="xs" type="button" className={cn(!column.getIsFiltered() && !open && "hidden", "group-hover/table:flex")}>
              <Icon name="filter" size="xs" />
            </Button>
          </div>
        </DropdownMenu.Trigger>
      </Tooltip>
      <DropdownMenu.Content align="end" className="py-0 border-0">
        <React.Suspense fallback={
          <div className="flex justify-center items-center py-4">
            <Spinner />
          </div>
        }>
          <Await
            resolve={filtersFn}
            errorElement={
              <p>Error loading!</p>
            }
          >
            {(data) => (
              <Fragment>
                <ColumnFilter
                  data={data[column.columnDef.accessorKey]}
                  column={column}
                  handleChange={handleChange}
                  isGrouped={column.columnDef.meta.filterIsGrouped}
                  isMultiple={isMultiple}
                />
              </Fragment>
            )}
          </Await>
        </React.Suspense>
      </DropdownMenu.Content>
    </DropdownMenu>
  )
}

const ColumnFilter = ({ data, column, handleChange, isGrouped, isMultiple }) => (
  <Command>
    <Command.Input placeholder="Select an item..." />
    <Command.List>
      <Command.Empty>No results found.</Command.Empty>
      {isGrouped
        ? data.map((cohort) => (
          <Command.Group key={cohort.title} heading={cohort.title}>
            {cohort.items.map(item => (
              <RenderItem key={item.id} item={item} column={column} handleChange={handleChange} />
            ))}
          </Command.Group>
        ))
        : data.map(item => (
          <RenderItem key={item.id} item={item} column={column} handleChange={handleChange} />
        ))}
    </Command.List>
  </Command>
)

const RenderItem = ({ item, column, handleChange }) => (
  <Command.Item key={item.id} value={item.id} onSelect={handleChange(item.id)}>
    {item.title}
    {column.getFilterValue()?.includes(item.id) && (
      <Command.Shortcut>
        <Icon name="check" size="xs" />
      </Command.Shortcut>
    )}
  </Command.Item>
)
